import { TextField } from '@mui/material';

const Width240Input = ({
  inputLabel,
  label,
  type,
  name,
  onChange,
  onBlur,
  error,
  helperText,
  width,
}) => {
  return (
    <>
      <div
        style={{
          margin: '0 0 8px 0',
          fontSize: '1.1rem',
          color: 'rgba(83 , 83, 83, 0.9)',
          maxWidth: '750px',
        }}
      >
        {inputLabel}
      </div>
      <TextField
        variant='filled'
        label={label}
        type={type}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        error={error}
        helperText={helperText}
        sx={{width: 240}}
        InputProps={{ inputProps: { step: 'any' } }}
      />
    </>
  );
};

export default Width240Input;