import { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Header from '../../Components/Headers/Header';
import SideBarPanel from '../../NavBars/SideBarPanel';
import TopBar from '../../NavBars/TopBar';
import styles from './EmissionsDetail.module.css';
import getSupplierDBData from '../../Utilities/DB Calls/getSupplierDBData';
import processSupplierData from './Utilities/processSupplierData';
import getColumns from './Utilities/getColumns';

// This component shows a table of all suppliers entered into the system
// by the user. The table has key information for each supplier in it.
const EmissionsDetail = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [suppliersData, setSuppliersData] = useState([]);
	const columns = getColumns();
  const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function getData() {
			const incomingSupplierData = await getSupplierDBData();
			const cleanedData = processSupplierData(incomingSupplierData);
			setSuppliersData(cleanedData);
     setLoading(false);
		}
		getData();
	}, []);

	return (
		<>
			<SideBarPanel />
			<main className={styles.supplierslistmain}>
				<nav>
					<TopBar />
				</nav>
				<Box m='20px'>
					<Header
						title='EMISSIONS DETAIL'
						subtitle='Emissions of Suppliers in the System'
					/>
					<div className={styles.supplierslisttablewrapper}>
						<Box
							m='40px 0 0 0'
							height='75vh'
							sx={{
								'& .MuiDataGrid-root': {
									border: 'none',
								},
								'& .MuiDataGrid-cell': {
									borderBottom: 'none',
								},
								'& .name-column--cell': {
									color: colors.greenAccent[300],
								},
								'& .MuiDataGrid-columnHeaders': {
									backgroundColor: colors.blueAccent[700],
									borderBottom: 'none',
								},
								'& .MuiDataGrid-virtualScroller': {
									backgroundColor: colors.primary[400],
								},
								'& .MuiDataGrid-footerContainer': {
									borderTop: 'none',
									backgroundColor: colors.blueAccent[700],
								},
								'& .MuiCheckbox-root': {
									color: `${colors.greenAccent[200]} !important`,
								},
								'& .MuiDataGrid-toolbarContainer .MuiButton-text': {
									color: `${colors.grey[100]} !important`,
								},
							}}
						>
							<Typography color={colors.redAccent[400]}>
								Emissions are shown in metric tons CO<sub>2</sub>e.
							</Typography>
							
								<DataGrid
                  rows={suppliersData}
									columns={columns}
                  loading={loading}
									components={{ Toolbar: GridToolbar }}
									sx={{
										fontSize: '14px',
									}}
								/>
							
						</Box>
					</div>
				</Box>
			</main>
		</>
	);
};

export default EmissionsDetail;
