import { useNavigate } from 'react-router-dom';
import { Grid, Box, Button } from '@mui/material';

import { Formik } from 'formik';
import * as yup from 'yup';

import AssessmentHeader from '../../../Components/Headers/AssessmentHeader';
import SelectInput from '../../../Components/SelectInput/SelectInput';
import Width240Input from '../../../Components/Width240Input/Width240Input';

import { insertIntoDB } from './Utilities/insertIntoDB';
import setAssessmentResponseField from '../SupplierInitialAssessment/Utilities/setAssessmentResponseField';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';
import styles from '../SupplierInitialAssessment.module.css';
import GoBackLink from '../Subcomponents/GoBackLink';

// Begin assessment 10005 component.
const SupplierAssessment10005 = ({ assessmentid, disableBtn }) => {
  // Gets assessment title
  const title = getAssessmentTitle(10005);
  const navigate = useNavigate();

  // Initial form values set to empty strings
  const initialValues = {
    companyName: undefined,
    gasolinecars: undefined,
    gasolinetrucks: undefined,
    dieseltrucks: undefined,
    dieselother: undefined,
    biodiesel: undefined,
    anthracite: undefined,
    browncoal: undefined,
    cokingcoal: undefined,
    crudeoil: undefined,
    naturalgas: undefined,
    kerosenejetfuel: undefined,
    jetgasoline: undefined,
    ethane: undefined,
    propane: undefined,
    biogas: undefined,
    othercombustion: '',
    specificindustry: '',
    representationalyear: '2023',
    calendarorfiscal: 'CY',
    allocationpercentage: undefined,
  };

  // Validates inputs for required fields
  const validateSchema = yup.object().shape({
    companyName: yup.string().required('Required'),
    gasolinecars: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    gasolinetrucks: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    dieseltrucks: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    dieselother: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    biodiesel: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    anthracite: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    browncoal: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    cokingcoal: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    crudeoil: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    naturalgas: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    kerosenejetfuel: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    jetgasoline: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    ethane: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    propane: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    biogas: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    othercombustion: yup.string().ensure().required('An answer is required'),
    specificindustry: yup.string().ensure().required('An answer is required'),
    representationalyear: yup
      .string()
      .required('Please enter a Year.'),
    calendarorfiscal: yup
      .string()
      .ensure()
      .required('Please select calendar or fiscal.'),
    allocationpercentage: yup
      .number()
      .required('Please enter a number.')
      .min(0, 'Must be a positive number.')
      .max(100, 'Must be less than 100.'),
  });

  // Inserts data into the datbase when submit button is clicked
  const handleFormSubmit = (values, { resetForm }) => {
    try {
      console.log('Form submit');
      if (!disableBtn) {
        insertIntoDB(values, assessmentid);
        setAssessmentResponseField(assessmentid);
      }
      resetForm(initialValues);
      navigate('/confirmation');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <main className={styles.initialAssessmentMain}>
        <Box mt='20px' sx={{ maxWidth: 'md' }}>
          <GoBackLink />
          <AssessmentHeader title={title} videoLink='https://youtu.be/Yi5-q4V2hGA' />
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <div>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validateSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      display='grid'
                      gap='0px'
                      gridTemplateColumns='repeat(1, minmax(0, 1fr))'
                    >
                      <div className={styles.supplierassessmentdirections}>
                        <p>
                          This assessment will ask for detailed information that
                          will help us estimate your Scope 1 greenhouse gas
                          (GHG) emissions. Scope 1 emissions are emissions from
                          combustion at facilities a company owns or has
                          operational control over.
                        </p>
                        <br />
                        <p>
                          Please use the latest year for which your company has
                          data.{' '}
                          <b>
                            In the answers below, only give numbers for
                            activities at offices/facilities you own or control.
                            Do not include activities oursourced to third
                            parties, activities by your customers, or activities
                            by your suppliers.
                          </b>{' '}
                          Many businesses, particularly service businesses, are
                          not involved in combustion. They will answer 0 to all
                          questions below.
                        </p>
                      </div>
                      <Box height={30} /> {/* Spacer */}
                      <div>
                        <Width240Input
                          inputLabel='Company Name'
                          type='text'
                          label='Company Name'
                          name='companyName'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={!!touched.companyName && !!errors.companyName}
                          helperText={touched.companyName && errors.companyName}
                        />
                      </div>
                      <Box height={30} /> {/* Spacer */}
                      {/* Any other electricity usage */}
                      <Width240Input
                        inputLabel='How much gasoline (gallons) does your company use annually in cars and light trucks that it owns or has operational control over? If none, enter 0.'
                        type='number'
                        label='Gasoline (gal)'
                        name='gasolinecars'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.gasolinecars && !!errors.gasolinecars}
                        helperText={touched.gasolinecars && errors.gasolinecars}
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much gasoline (gallons) does your company use annually in heavy duty trucks it owns or has operational control over?'
                        type='number'
                        label='Gasoline (gal)'
                        name='gasolinetrucks'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          !!touched.gasolinetrucks && !!errors.gasolinetrucks
                        }
                        helperText={
                          touched.gasolinetrucks && errors.gasolinetrucks
                        }
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much diesel (gallons) does your company use annually in heavy duty trucks it owns or has operational control over?'
                        type='number'
                        label='Diesel (gal)'
                        name='dieseltrucks'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.dieseltrucks && !!errors.dieseltrucks}
                        helperText={touched.dieseltrucks && errors.dieseltrucks}
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much diesel (gallons) does your company use for other purposes (non-vehicle uses)?'
                        type='number'
                        label='Diesel (gal)'
                        name='dieselother'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.dieselother && !!errors.dieselother}
                        helperText={touched.dieselother && errors.dieselother}
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much biodiesel (gallons) does your company use? (Do not include these biodiesel gallons in the diesel numbers for the previous 2 questions.)'
                        type='number'
                        label='Biodiesel (gal)'
                        name='biodiesel'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.biodiesel && !!errors.biodiesel}
                        helperText={touched.biodiesel && errors.biodiesel}
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much (short tons) Anthracite coal does your company burn annually?'
                        type='number'
                        label='Short tons'
                        name='anthracite'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.anthracite && !!errors.anthracite}
                        helperText={touched.anthracite && errors.anthracite}
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much brown coal (short tons) does your company burn annually?'
                        type='number'
                        label='Short tons'
                        name='browncoal'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.browncoal && !!errors.browncoal}
                        helperText={touched.browncoal && errors.browncoal}
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much coking coal (short tons) does your company burn annually?'
                        type='number'
                        label='Short tons'
                        name='cokingcoal'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.cokingcoal && !!errors.cokingcoal}
                        helperText={touched.cokingcoal && errors.cokingcoal}
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much crude oil (barrels) does your company burn annually?'
                        type='number'
                        label='Oil (barrels)'
                        name='crudeoil'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.crudeoil && !!errors.crudeoil}
                        helperText={touched.crudeoil && errors.crudeoil}
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much natural gas (MMBtu) does your company burn annually?'
                        type='number'
                        label='MMBtu'
                        name='naturalgas'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.naturalgas && !!errors.naturalgas}
                        helperText={touched.naturalgas && errors.naturalgas}
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much kerosene-type jet fuel (gallons) does your company use annually?'
                        type='number'
                        label='Gals'
                        name='kerosenejetfuel'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          !!touched.kerosenejetfuel && !!errors.kerosenejetfuel
                        }
                        helperText={
                          touched.kerosenejetfuel && errors.kerosenejetfuel
                        }
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much jet gasoline (gallons) does your company use annually?'
                        type='number'
                        label='Gals'
                        name='jetgasoline'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.jetgasoline && !!errors.jetgasoline}
                        helperText={touched.jetgasoline && errors.jetgasoline}
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much ethane (gallons) does your company use annually?'
                        type='number'
                        label='Ethane (gals)'
                        name='ethane'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.ethane && !!errors.ethane}
                        helperText={touched.ethane && errors.ethane}
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much propane (gallons) does your company burn annually?'
                        type='number'
                        label='Propane (gals)'
                        name='propane'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.propane && !!errors.propane}
                        helperText={touched.propane && errors.propane}
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='How much biogas (cubic meters) does your company burn annually?'
                        type='number'
                        label='Cu meters'
                        name='biogas'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.biogas && !!errors.biogas}
                        helperText={touched.biogas && errors.biogas}
                      />
                      <Box height={30} /> {/* Spacer */}
                      <SelectInput
                        inputLabel='Does your company generate other Scope 1 emissions, or is it involved in combustion other than what is described above? If yes, our support team will contact you.'
                        name='othercombustion'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.othercombustion}
                        error={
                          !!touched.othercombustion && !!errors.othercombustion
                        }
                        helperText={
                          touched.othercombustion && errors.othercombustion
                        }
                        menuItemValue='Yes'
                        menuItemValue2='No'
                      />
                      <Box height={30} /> {/* Spacer */}
                      <SelectInput
                        inputLabel='Is your company involved in any of the following: chemical production, oil and gas, steel production, aluminum production, waste and landfills, pulp and paper, cement production, mining, or other metal production? If so, our support team will contact you to discuss industry specific Scope 1 measurement.'
                        name='specificindustry'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.specificindustry}
                        error={
                          !!touched.specificindustry &&
                          !!errors.specificindustry
                        }
                        helperText={
                          touched.specificindustry && errors.specificindustry
                        }
                        menuItemValue='Yes'
                        menuItemValue2='No'
                      />
                      <Box height={30} /> {/* Spacer */}
                      <SelectInput
                        inputLabel='Which year does the above data represent?'
                        type='number'
                        name='representationalyear'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.representationalyear}
                        error={
                          !!touched.representationalyear &&
                          !!errors.representationalyear
                        }
                        helperText={
                          touched.representationalyear &&
                          errors.representationalyear
                        }
                        // menuItemValue='2022'
                        menuItemValue2='2023'
                        // menuItemValue3='2024'
                      />
                      <Box height={30} /> {/* Spacer */}
                      <SelectInput
                        inputLabel='Reporting year'
                        name='calendarorfiscal'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.calendarorfiscal}
                        error={
                          !!touched.calendarorfiscal &&
                          !!errors.calendarorfiscal
                        }
                        helperText={
                          touched.calendarorfiscal && errors.calendarorfiscal
                        }
                        menuItemValue='CY'
                        // menuItemValue2='FY'
                      />
                      <Box height={30} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='What percentage of your company&#39;s Scope 1 emissions should be allocated to this customer? The most common method is to determine how much of your total revenue comes from the customer. For example, if you have $100,000 in revenue and $4,500 comes from the customer, then enter 4.5 here. Another way is to determine how much of your total emissions are related to the customer, based on business activities. Enter the percentage as a number without a percent sign (i.e. for 15% enter 15).' 
                        label='Number'
                        type='number'
                        name='allocationpercentage'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          !!touched.allocationpercentage &&
                          !!errors.allocationpercentage
                        }
                        helperText={
                          touched.allocationpercentage &&
                          errors.allocationpercentage
                        }
                      />
                    </Box>
                    <Box display='flex' sx={{ m: '25px 0px 50px 0px' }}>
                      <Button
                        type='submit'
                        color='secondary'
                        variant='contained'
                      >
                        Submit Form
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </div>
          </Grid>
        </Box>
      </main>
    </>
  );
};

export default SupplierAssessment10005;
