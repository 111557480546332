import { useState, useEffect } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../Components/Headers/Header';
import SideBarPanel from '../../NavBars/SideBarPanel';
import TopBar from '../../NavBars/TopBar';
import styles from './AddSupplier.module.css';
import MaterialPopup from '../../Components/Popup/MaterialPopup';
import { supabase } from '../../Utilities/SupabaseClient';
import { insertIntoDB } from './Utilities/insertIntoDB';

// Renders the a form using Formik to allow the user to add
// a supplier to the supplier list.
const AddSupplier = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  const handleFormSubmit = async (values, { resetForm }) => {
    setIsPopupOpen(true);
    try {
      if (session) await insertIntoDB(session.user.id, values);
      resetForm(initialValues);
    } catch (error) {
      console.log(error); // Console logging error for now
    }
  };

  return (
    <>
      <SideBarPanel />
      <main className={styles.addsuppliermain}>
        <nav>
          <TopBar />
        </nav>
        <Box m='20px'>
          <Header
            title='ADD SUPPLIER'
            subtitle='Add a supplier to your supplier list'
          />
          <div className={styles.addsupplierformwrapper}>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display='grid'
                    gap='30px'
                    gridTemplateColumns='repeat(4, minmax(0, 1fr))'
                    sx={{
                      '& > div': {
                        gridColumn: isNonMobile ? undefined : 'span 4',
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant='filled'
                      type='text'
                      label='Company Name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.companyName}
                      name='companyName'
                      error={!!touched.companyName && !!errors.companyName}
                      helperText={touched.companyName && errors.companyName}
                      sx={{ gridColumn: 'span 2' }}
                    />
                    <TextField
                      fullWidth
                      variant='filled'
                      type='text'
                      label='Contact Email'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name='email'
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      sx={{ gridColumn: 'span 2' }}
                    />
                    <TextField
                      fullWidth
                      variant='filled'
                      type='text'
                      label='Company Contact First Name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      name='firstName'
                      error={!!touched.firstName && !!errors.firstName}
                      helperText={touched.firstName && errors.firstName}
                      sx={{ gridColumn: 'span 2' }}
                    />
                    <TextField
                      fullWidth
                      variant='filled'
                      type='text'
                      label='Company Contact Last Name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      name='lastName'
                      error={!!touched.lastName && !!errors.lastName}
                      helperText={touched.lastName && errors.lastName}
                      sx={{ gridColumn: 'span 2' }}
                    />
                    <TextField
                      fullWidth
                      variant='filled'
                      type='text'
                      label='Phone Number'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phoneNumber}
                      name='phoneNumber'
                      error={!!touched.phoneNumber && !!errors.phoneNumber}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      sx={{ gridColumn: 'span 4' }}
                    />
                  </Box>
                  <MaterialPopup
                    isOpen={isPopupOpen}
                    setOpenFunc={setIsPopupOpen}
                    title='Success'
                    dialogContent='The supplier has been added. You can see all of the suppliers you have added on the Suppliers List page.'
                  />
                  <Box display='flex' justifyContent='end' mt='20px'>
                    <Button type='submit' color='secondary' variant='contained'>
                      Add New Supplier
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </div>
        </Box>
      </main>
    </>
  );
};

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  companyName: yup.string().required('Required'),
  email: yup.string().email('Invalid email').required('Required'),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
});
const initialValues = {
  companyName: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
};

export default AddSupplier;
