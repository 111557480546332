import { supabase } from '../../../Utilities/SupabaseClient';

// When the user edits the information for a supplier, this
// function changes the information in the DB.
const saveSupplierInfoChange = async (fieldNameForDB, newValue, uuid) => {
  let result;
  try {
    // Using code form from Supabase docs. 
    // https://supabase.com/docs/guides/database/tables 
    result = await supabase
    .from('suppliers')
    .update({ [fieldNameForDB]: newValue })
    .eq('uuid', uuid)
  } catch(e) {
    console.log(e);
    throw(e);
  }
  // Second error block here in case the catch block does not
  // recognize the error. Not sure how Supabase handles the error
  // message so including this here. May not be necessary.
  if (result && result.error) {
    throw (result.error);
  }
}

export default saveSupplierInfoChange;