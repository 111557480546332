import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

// Returns the columns needed to populate the MUI Grid.
const getColumns = (colors) => {
  const columns = [
    { field: "company_name", headerName: "Company Name", flex: 1 },
    {
      field: "contact_first_name",
      headerName: "First Name",
      flex: 0.7,
      cellClassName: "contact-first-name-column--cell",
    },
    {
      field: "contact_last_name",
      headerName: "Last Name",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 0.7,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "supplier_page",
      headerName: "Supplier Page",
      flex: 1,
      // Renders a button within the MUI Grid.
      renderCell: ({ row: { supplier_page } }) => {
        return (
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <Button
                component={Link}
                to={supplier_page}
                sx={{
                  backgroundColor: colors.greenAccent[600],
                  '&:hover': {
                    backgroundColor: colors.greenAccent[700],
                  },
                  textTransform: 'none',
                  minHeight: '0',
                  minWidth: '0',
                  padding: '4px',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  fontSize: '12px'
                }}
              >
                Open
              </Button>
            </Box>
        );
      }
    }
  ];
  return columns;
}

export default getColumns;
