import { supabase } from '../../../Utilities/SupabaseClient';

// This function gets the suppliers from the DB. It should only get
// suppliers associated with the logged in user, given the RLS setup.
export const getSuppliersKPIFromDB = async () => {
  try {
    const session = await supabase.auth.getSession();
    const userid = session.data.session.user.id;
    // RLS should only return the suppliers associated with the user
    // that is logged in. However, using .eq here to add extra
    // protection.
    const { data, err } = await supabase.from('suppliers').select('*').eq('user_added_supplier_id', userid);
    // Error block here in case the catch block does not
    // recognize the error. Not sure how Supabase handles the error
    // message so including this here. May not be necessary.
    if (err) throw err;
    return data.length;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
