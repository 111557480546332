import { supabase } from '../../../../Utilities/SupabaseClient';

// Stores user responses to assessment 10004 in the DB.
export const insertIntoDB = async (values, assessmentid) => {
  try {
    console.log("Inserting into DB 10004");
    const { error } = await supabase
      // Selects the proper table
      .from('supplier_assessment_10004')
      // Inserts values from form into table
      .insert({
            assessment_uuid: assessmentid,
            company_name: values.companyName,
            scope_2_emissions: values.scope2Emissions ? values.scope2Emissions : null,
            annual_scope_emissions: values.annualScopeEmissions,
            representational_year: parseInt(values.representationalYear),
            calendar_or_fiscal_year: values.calendarOrFiscal,
            // approximate_annual_revenue: values.approximateAnnualRevenue,
            allocation_percentage: values.allocationPercentage ? values.allocationPercentage : null,
        }, { returning: 'minimal' }
        // add returning: 'minimal' to avoid error on insert for anon role
        // { onConflict: 'assessment_uuid' },
        // { ignoreDuplicates: true },
      )
    if (error) throw error;   
  } catch (error) {
    console.log(error);
    throw error;
  }
};
