import { supabase } from '../../../Utilities/SupabaseClient';
import { v4 as uuidv4 } from 'uuid';

// The DB has a log of all assessments sent. This function updates
// that log.
const addToAssessmentsSentTable = async (supplierid, assessmentNumber, loggedinuserid) => {
  const uuid = uuidv4();
  let result;
  try {
    result = await supabase
    .from('assessments_sent')
    .insert([{
      sending_user_id: loggedinuserid,
      supplier_uuid: supplierid,
      assessment_number: assessmentNumber,
      assessment_uuid: uuid,
      response: false
    }]);
  } catch (e) {
    throw (e)
  }

  if (result && result.error) {
    throw (result.error);
  }
}

export default addToAssessmentsSentTable;