import { useNavigate, Link } from 'react-router-dom';
import styles from '../SupplierInitialAssessment.module.css';

const GoBackLink = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.assessmentgoback}>
      <Link onClick={(e) => {
        e.preventDefault();
        navigate(-1);
      }}>
        Go back
      </Link>
    </div>
  );

}

export default GoBackLink;
