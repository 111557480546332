import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { supabase } from '../../Utilities/SupabaseClient';
import SideBarPanel from '../../NavBars/SideBarPanel';
import TopBar from '../../NavBars/TopBar';
import Header from '../../Components/Headers/Header';
import styles from './Dashboard.module.css';
import EmailIcon from '@mui/icons-material/Email';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import StatBox from '../../Components/ChartComponents/StatBox';
import BarChart from '../../Components/ChartComponents/BarChart';
import getSupplierEmissions from './Utilities/getSupplierEmissions';
import getRecentResponses from './Utilities/getRecentResponses';
import { getSuppliersKPIFromDB } from './Utilities/getSuppliersKPIFromDB';
import { getAssessmentsSentKPIFromDB } from './Utilities/getAssessmentsSentKPIFromDB';
import { getResponsesRecievedKPIFromDB } from './Utilities/getResponsesRecievedKPIFromDB';
import { getEmissionsKPIFromDB } from './Utilities/getEmissionsKPIFromDB';
import supplierEmissionsDemoAccounts from './Utilities/supplierEmissionsDemoAccounts';
import { numberWithCommas } from '../../Utilities/Formatting/numberWithCommas';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import { Link } from 'react-router-dom';

// This is the component that renders the Dashboard for the app.
// There is a grid with 2 rows. The top row of the grid has 4
// cards. The second row has a bar chart (emissions chart)
// and a box with recent assessment submissions.
const Dashboard = () => {
	const navigate = useNavigate();
	const [supplierEmissionsLoading, setSupplierEmissionsLoading] =
		useState(true);
	const [responsesLoading, setResponsesLoading] = useState(true);
	const [supplierKPILoading, setSupplierKPILoading] = useState(true);
	const [assessmentKPILoading, setAssessmentKPILoading] = useState(true);
	const [responseKPILoading, setResponseKPILoading] = useState(true);
	const [emissionsKPILoading, setEmissionsKPILoading] = useState(true);

	const [supplierEmissions, setSupplierEmissions] = useState([]);
	const [responsesArr, setResponsesArr] = useState([]);
	const [supplierKPI, setSupplierKPI] = useState();
	const [suppliersSentAssessment, setSuppliersSentAssessment] = useState();
	const [assessmentKPI, setAssessmentKPI] = useState();
	const [responseKPI, setResponseKPI] = useState([]);
	const [emissionsKPI, setEmissionsKPI] = useState();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	useEffect(() => {
		supabase.auth.onAuthStateChange((_event) => {
			if (_event === 'PASSWORD_RECOVERY') {
				navigate('/newpassword');
			}
		});
	}, [navigate]);

	useEffect(() => {
		async function getData() {
			// Get emissions data for the barchart
			const demoData = supplierEmissionsDemoAccounts();
			const session = await supabase.auth.getSession();
			const useremail = session.data.session.user.email;
			if (useremail.substring(0, 4) === 'demo') {
				setSupplierEmissions(demoData);
				setSupplierEmissionsLoading(false);
				return;
			}
			const incomingData = await getSupplierEmissions();
			setSupplierEmissions(incomingData);
      setSupplierEmissionsLoading(false);

		}
		getData();
	}, []);

	useEffect(() => {
		async function getData() {
			// Gets recent assessment reponses for the box
			// that shows this information.
			const incomingData = await getRecentResponses();
			setResponsesArr(incomingData);
			setResponsesLoading(false);
		}
		getData();
	}, []);

	// suppierKPI is the number of suppliers tracked by the current
	// user account. This is displayed in the first box on the
	// dashboard.
	useEffect(() => {
		async function getData() {
			// Gets recent assessment reponses for the box
			// that shows this information.
			const incomingData = await getSuppliersKPIFromDB();
			setSupplierKPI(incomingData);
			setSupplierKPILoading(false);
		}
		getData();
	}, [supplierKPI]);

	// assessmentKPI is the number of assessments sent by the current
	// user account. This is displayed in the second box on the
	// dashboard.
	useEffect(() => {
		async function getData() {
			// Gets recent assessment reponses for the box
			// that shows this information.
			const incomingData = await getAssessmentsSentKPIFromDB();
			setAssessmentKPI(incomingData[0]);
			setSuppliersSentAssessment(incomingData[1]);
			setAssessmentKPILoading(false);
		}
		getData();
	}, [assessmentKPI]);

	useEffect(() => {
		async function getData() {
			// Gets recent assessment reponses for the box
			// that shows this information.
			const incomingData = await getResponsesRecievedKPIFromDB();
			setResponseKPI(incomingData);
			setResponseKPILoading(false);
		}
		getData();
	}, []);

	useEffect(() => {
		async function getData() {
			// Get recent assessment reponses for the box
			// that shows this information.
			const incomingData = await getEmissionsKPIFromDB(); // Commenting for now
			const formattedData = numberWithCommas(incomingData);
			setEmissionsKPI(formattedData);
			setEmissionsKPILoading(false);
		}
		getData();
	}, [emissionsKPI]);

	// supplierProgress is the portion of the suppliers that have
	// been sent an assessment.
	let supplierProgress = 0;
	if (supplierKPI && suppliersSentAssessment) {
		supplierProgress = suppliersSentAssessment / supplierKPI;
	}

	return (
		<>
			<SideBarPanel />
			<div className={styles.dashboardwrapper}>
				<main className={styles.dashboardmain}>
					<nav>
						<TopBar />
					</nav>
					<Box m='20px'>
						<Box>
							<Header title='DASHBOARD' subtitle='Welcome to your dashboard' />
						</Box>

						{/* GRID & CHARTS */}
						<Box
							display='grid'
							gridTemplateColumns='repeat(12, 1fr)'
							gridAutoRows='140px'
							gap='20px'
						>
							{/* ROW 1 */}
							<Box
								gridColumn='span 3'
								backgroundColor={colors.primary[400]}
								display='flex'
								alignItems='center'
								justifyContent='center'
							>
								<StatBox
									title={supplierKPI}
									subtitle='Suppliers Tracked'
									icon={
										<PersonAddIcon
											sx={{
												color: colors.greenAccent[600],
												fontSize: '26px',
											}}
										/>
									}
								/>
							</Box>
							<Box
								gridColumn='span 3'
								backgroundColor={colors.primary[400]}
								display='flex'
								alignItems='center'
								justifyContent='center'
							>
								<StatBox
									title={assessmentKPI}
									subtitle='Assessments Sent'
									progress={supplierProgress}
									progresspct={`${(supplierProgress * 100).toFixed(0)}%`}
									icon={
										<EmailIcon
											sx={{
												color: colors.greenAccent[600],
												fontSize: '26px',
											}}
										/>
									}
								/>
							</Box>
							<Box
								gridColumn='span 3'
								backgroundColor={colors.primary[400]}
								display='flex'
								alignItems='center'
								justifyContent='center'
							>
								<StatBox
									title={responseKPI[0]}
									subtitle='Responses'
									progress={responseKPI[2]}
									progresspct={`${responseKPI[1]}%`}
									icon={
										<PersonAddIcon
											sx={{
												color: colors.greenAccent[600],
												fontSize: '26px',
											}}
										/>
									}
								/>
							</Box>
							<Box
								gridColumn='span 3'
								backgroundColor={colors.primary[400]}
								display='flex'
								alignItems='center'
								justifyContent='center'
							>
								<StatBox
									title={emissionsKPI}
									// title={191.3}
									subtitle='Emissions (Metric Tons)'
									icon={
										<CloudCircleIcon
											sx={{
												color: colors.greenAccent[600],
												fontSize: '36px',
											}}
										/>
									}
								/>
							</Box>
							{/* ROW 2 */}
							<Box
								gridColumn='span 8'
								gridRow='span 3'
								backgroundColor={colors.primary[400]}
							>
								<Typography
									variant='h5'
									fontWeight='600'
									sx={{ padding: '30px 30px 0 30px' }}
								>
									Emissions (Attributed)
								</Typography>
								<Box height='400px' mt='-20px'>
									<BarChart isDashboard={true} data={supplierEmissions} />
								</Box>
							</Box>
							<Box
								gridColumn='span 4'
								gridRow='span 3'
								backgroundColor={colors.primary[400]}
								overflow='auto'
							>
								<Box
									display='flex'
									justifyContent='space-between'
									alignItems='center'
									borderBottom={`2px solid ${colors.grey[700]}`}
									colors={colors.grey[100]}
									p='15px'
								>
									<Typography
										color={colors.grey[100]}
										variant='h5'
										fontWeight='600'
									>
										Recent Responses
									</Typography>
								</Box>
								{responsesArr.slice(0, 5).map((transaction, index) => {
									return (
										<Box
											key={index}
											display='flex'
											justifyContent='space-between'
											alignItems='center'
											borderBottom={`2px solid ${colors.grey[700]}`}
											px='15px'
											py='10px'
										>
											<Box>
												<Link
													to={`/response/${transaction['uuid']}`}
													className={styles.link}
												>
													<Typography
														color={colors.greenAccent[500]}
														variant='h5'
														fontWeight='600'
													>
														{transaction['company']}
													</Typography>
													<Typography color={colors.grey[100]}>
														{transaction['assessment']}
													</Typography>
													<Typography color={colors.blueAccent[500]}>
														{transaction['date']}
													</Typography>
												</Link>
											</Box>
										</Box>
									);
								})}
							</Box>
						</Box>
					</Box>
					<Backdrop
						sx={{
							color: 'blue',
							zIndex: (theme) => theme.zIndex.drawer + 1,
						}}
						open={supplierKPILoading || responseKPILoading || assessmentKPILoading || responsesLoading || emissionsKPILoading || supplierEmissionsLoading}
					>
						<CircularProgress color='inherit' />
					</Backdrop>
				</main>
			</div>
		</>
	);
};

export default Dashboard;
