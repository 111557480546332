import { Grid, TextField, Box } from '@mui/material';

const TextAreaInput = ({
  name,
  onBlur,
  onChange,
  inputLabel,
  error,
  helperText,
}) => {
  return (
    <div>
      <Box height={20} />
      <p
        style={{
          margin: '0 0 0 .8rem',
          fontSize: '1.1rem',
          color: 'rgba(83 , 83, 83, 0.9)',
          maxWidth: '750px',
        }}
      >
        {inputLabel}
      </p>
      <Grid item xs={12}>
        <TextField
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          error={error}
          helperText={helperText}
          multiline
          variant='filled'
          fullWidth
          rows={8}
          sx={{ m: 1 }}
        />
      </Grid>
    </div>
  );
};

export default TextAreaInput;
