import { FIRST_ASSESSMENT_NUMBER } from '../../../Utilities/Constants';

// Returns the title of an assessment based on it's number.
// This function is essentially a dictionary.
const getAssessmentTitle = (assessmentNumber) => {
  const titles = [
    'Initial Climate Assessment (Part A)',
    'Initial Climate Assessment (Part B)',
    'Scope 1 Basics',
    'Scope 2 Basics',
    'Scope 1 Calculation',
    'Scope 2 Calculation',
    'Specific Product Emissions'
  ];
  const idx = parseInt(assessmentNumber) - FIRST_ASSESSMENT_NUMBER;
  return titles[idx];
}

export default getAssessmentTitle;