// Returns the assessment status text to display and
// color to display it in. 
const getAssessmentStatusText = (assessmentStatus) => {
  switch(assessmentStatus) {
    case 'not_sent':
      return 'Not sent';
    case 'sent':
      return 'Sent, awaiting reply';
    case 'response_received':
      return 'Response received';
    default:
      return 'Not sent';
  }
}

export default getAssessmentStatusText;