import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { Button } from '@mui/material';

// Renders at Material UI popup. isOpen controls whether the popup
// is open. setOpenFunc is the setter function that can toggle the 
// popup open and closed.
const MaterialPopup = ({isOpen, setOpenFunc, title, dialogContent}) => {
  const handleClose = () => {
    setOpenFunc(false);
  }
  return(
    <Dialog open={isOpen}>
      <DialogTitle fontSize="24px" fontWeight="600">
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="h5"
        >
          {dialogContent}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button 
          variant="text"
          size="medium"
          sx={{
            color: '#1976d2',
            fontSize: '17px', 
            fontWeight:'600',
            ':hover': {
              bgcolor: '#ebf2fa' 
            }
          }}
          onClick={handleClose}
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MaterialPopup;