import { supabase } from '../../../Utilities/SupabaseClient';

// This function gets the suppliers from the DB. It should only get
// suppliers associated with the logged in user, given the RLS setup.
export const getResponsesRecievedKPIFromDB = async () => {
  const {
    data: { user },
  } = await supabase.auth.getUser();

  try {
    const { data, err } = await supabase
      .from('assessments_sent')
      .select('response')
      .eq('sending_user_id', user.id);

    if (err) throw err;

    if (data) {
      // Initialize the variable to count the true responses
      let trueCount = 0;
      // Initialize the variable to count the false responses
      let falseCount = 0;

      data.forEach((item) => {
        if (item.response) {
          return trueCount++;
        } else if (!item.response) {
          return falseCount++;
        }
      });

      let percentage = 0;
      let decimal = 0;
      if ((trueCount + falseCount) > 0) {
        percentage = ((trueCount / (trueCount + falseCount)) * 100).toFixed(0)
        decimal = trueCount / (trueCount + falseCount);  
      }
      return [trueCount, percentage, decimal];
    }
    // Error block here in case the catch block does not
    // recognize the error. Not sure how Supabase handles the error
    // message so including this here. May not be necessary.
  } catch (e) {
    console.log(e);
    throw e;
  }
};
