import { Link } from 'react-router-dom';
import styles from './AssessmentResponseHeader.module.css';

const AssessmentResponseHeader = ({ title }) => {
  return( 
    <>
      <div className={styles.assesssmentresponseheader}>
        {title}
      </div>
      <div className={styles.assesssmentresponseheaderback}>
        <Link to="/assessmentresponses">Back to Reponses List</Link>
      </div>
    </>
  );
};

export default AssessmentResponseHeader;