import { useEffect, useState } from 'react';
import AppMain from './MainPages/AppMain/AppMain.js';
import { supabase } from './Utilities/SupabaseClient.js';
import LoginWidget from './Components/LoginWidget/LoginWidget';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';

function App() {
  return (
    <div className='app'>
      <AppMain />
    </div>
  );
}

export default App;

