import styles from './AssessmentResponseItem.module.css';

const ResponseItem = ( {question, answer} ) => {
  let answerToShow = 'N/A';
  if (answer) answerToShow = answer;

  return(
    <>
      <div className={styles.responseitemquestion}>
        {question}
      </div>
      <div className={styles.responseitemanswer}>
        {answerToShow}
      </div>
    </>
  );
}

export default ResponseItem;