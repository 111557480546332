import { supabase } from '../../../Utilities/SupabaseClient';

const getSupplierIDFromDB = async (assessmentid) => {
  const { data: supplier_uuid, err } = await supabase
    .from('assessments_sent')
    .select('supplier_uuid')
    .eq('assessment_uuid', assessmentid);

  let supplierUUID = '';
  if (supplier_uuid) {
    supplierUUID = supplier_uuid[0].supplier_uuid;
  }

  return supplierUUID;
};

export default getSupplierIDFromDB;