import { useNavigate } from 'react-router-dom';
import { Grid, Box, Button } from '@mui/material';

import { Formik } from 'formik';
import * as yup from 'yup';

import AssessmentHeader from '../../../Components/Headers/AssessmentHeader';
import OneLineInput from '../../../Components/OneLineInput/OneLineInput';
import SelectInput from '../../../Components/SelectInput/SelectInput';
import TextAreaInput from '../../../Components/TextAreaInput/TextAreaInput';

import { insertIntoDB } from './Utilities/insertIntoDB';
import setAssessmentResponseField from '../SupplierInitialAssessment/Utilities/setAssessmentResponseField';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';
import styles from '../SupplierInitialAssessment.module.css';
import GoBackLink from '../Subcomponents/GoBackLink';

// Begin assessment 2 form component
const SupplierInitialAssessment2 = ({ assessmentid, disableBtn }) => {
  // Gets assessment title
  const title = getAssessmentTitle(10002);
  const navigate = useNavigate();

  // Initial form values set to empty strings
  const initialValues = {
    companyName: '',
    climateReductionTargetInPlace: '',
    climateReductionTargetDescription: '',
    offSetPrograms: '',
    offsetProgramDescription: '',
    climateRiskAssessment: '',
    currentClimateRiskDescription: '',
    materialContractsOrOperations: '',
    materialContractsDescription: '',
  };

  // Validates inputs for required fields
  const validateSchema = yup.object().shape({
    companyName: yup.string().required('Required'),
    climateReductionTargetInPlace: yup
      .string()
      .ensure()
      .required('Please select an answer'),
    climateReductionTargetDescription: yup
      .string()
      .ensure()
      .when('climateReductionTargetInPlace', {
        is: 'Yes',
        then: yup.string().required('Please enter in a description.'),
      }),
    offSetPrograms: yup
      .string()
      .ensure()
      .required('Please select an answer')
      .oneOf(['Yes', 'No']),
    offsetProgramDescription: yup
      .string()
      .ensure()
      .when('offSetPrograms', {
        is: 'Yes',
        then: yup.string().required('Please enter in a description.'),
      }),
    climateRiskAssessment: yup
      .string()
      .ensure()
      .required('Please select an answer')
      .oneOf(['Yes', 'No']),
    currentClimateRiskDescription: yup
      .string()
      .ensure()
      .when('climateRiskAssessment', {
        is: 'Yes',
        then: yup.string().required('Please enter in a description.'),
      }),
    materialContractsOrOperations: yup
      .string()
      .ensure()
      .required('Please select an answer')
      .oneOf(['Yes', 'No', 'No answer']),
    materialContractsDescription: yup
      .string()
      .ensure()
      .when('materialContractsOrOperations', {
        is: 'Yes',
        then: yup.string().required('Please enter in a description.'),
      }),
  });

  // Inserts data into the datbase when submit button is clicked
  const handleFormSubmit = (values, { resetForm }) => {
    try {
      if (!disableBtn) {
        insertIntoDB(values, assessmentid);
        setAssessmentResponseField(assessmentid);  
      }
      resetForm(initialValues);
      navigate('/confirmation');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <main className={styles.initialAssessmentMain}>
        <Box mt='20px'>
          <GoBackLink />
          <AssessmentHeader title={title} />
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <div>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validateSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      display='grid'
                      gap='30px'
                      gridTemplateColumns='repeat(1, minmax(0, 1fr))'
                    >
                      <div>
                        <OneLineInput
                          inputLabel='Company Name'
                          type='text'
                          label='Company Name'
                          name='companyName'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={!!touched.companyName && !!errors.companyName}
                          helperText={touched.companyName && errors.companyName}
                        />
                      </div>

                      <div>
                        <SelectInput
                          inputLabel='Climate reduction targets in place?'
                          name='climateReductionTargetInPlace'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.climateReductionTargetInPlace}
                          error={
                            !!touched.climateReductionTargetInPlace &&
                            !!errors.climateReductionTargetInPlace
                          }
                          helperText={
                            touched.climateReductionTargetInPlace &&
                            errors.climateReductionTargetInPlace
                          }
                          menuItemValue='Yes'
                          menuItemValue2='No'
                        />
                        {values.climateReductionTargetInPlace === 'Yes' ? (
                          <TextAreaInput
                            inputLabel='Describe your current targets.'
                            name='climateReductionTargetDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={
                              !!touched.climateReductionTargetDescription &&
                              !!errors.climateReductionTargetDescription
                            }
                            helperText={
                              touched.climateReductionTargetDescription &&
                              errors.climateReductionTargetDescription
                            }
                          />
                        ) : null}
                      </div>

                      <div>
                        <SelectInput
                          inputLabel='Does your company buy carbon offsets or participate in
                        offset programs?'
                          name='offSetPrograms'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.offSetPrograms}
                          error={
                            !!touched.offSetPrograms && !!errors.offSetPrograms
                          }
                          helperText={
                            touched.offSetPrograms && errors.offSetPrograms
                          }
                          menuItemValue='Yes'
                          menuItemValue2='No'
                        />
                        {values.offSetPrograms === 'Yes' ? (
                          <TextAreaInput
                            inputLabel='Describe your offset programs.'
                            name='offsetProgramDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={
                              !!touched.offsetProgramDescription &&
                              !!errors.offsetProgramDescription
                            }
                            helperText={
                              touched.offsetProgramDescription &&
                              errors.offsetProgramDescription
                            }
                          />
                        ) : null}
                      </div>

                      <div>
                        <SelectInput
                          inputLabel='Does the company assess climate risks to its business?'
                          name='climateRiskAssessment'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.climateRiskAssessment}
                          error={
                            !!touched.climateRiskAssessment &&
                            !!errors.climateRiskAssessment
                          }
                          helperText={
                            touched.climateRiskAssessment &&
                            errors.climateRiskAssessment
                          }
                          menuItemValue='Yes'
                          menuItemValue2='No'
                        />
                        {values.climateRiskAssessment === 'Yes' ? (
                          <TextAreaInput
                            inputLabel='Describe the current climate related risks to your
                          business.'
                            name='currentClimateRiskDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={
                              !!touched.currentClimateRiskDescription &&
                              !!errors.currentClimateRiskDescription
                            }
                            helperText={
                              touched.currentClimateRiskDescription &&
                              errors.currentClimateRiskDescription
                            }
                          />
                        ) : null}
                      </div>

                      <div>
                        <SelectInput
                          inputLabel='Are there any material contracts or operations which the company considers 
to be not particular environmentally friendly? Respond with "No answer" if you prefer not to answer.'
                          name='materialContractsOrOperations'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.materialContractsOrOperations}
                          error={
                            !!touched.materialContractsOrOperations &&
                            !!errors.materialContractsOrOperations
                          }
                          helperText={
                            touched.materialContractsOrOperations &&
                            errors.materialContractsOrOperations
                          }
                          menuItemValue='Yes'
                          menuItemValue2='No'
                          menuItemValue3='No answer'
                        />
                        {values.materialContractsOrOperations === 'Yes' ? (
                          <TextAreaInput
                            inputLabel='Describe these contracts or operations.'
                            name='materialContractsDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={
                              !!touched.materialContractsDescription &&
                              !!errors.materialContractsDescription
                            }
                            helperText={
                              touched.materialContractsDescription &&
                              errors.materialContractsDescription
                            }
                          />
                        ) : null}
                      </div>
                    </Box>

                    <Box display='flex' sx={{ m: '25px 0px 50px 0px' }}>
                      <Button
                        type='submit'
                        color='secondary'
                        variant='contained'
                      >
                        Submit Form
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </div>
          </Grid>
        </Box>
      </main>
    </>
  );
};

export default SupplierInitialAssessment2;
