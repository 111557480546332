import { supabase } from '../../../../Utilities/SupabaseClient';

// Stores user responses to assessment 10003 in the DB.
export const insertIntoDB = async (values, assessmentid) => {
  try {
    const { error } = await supabase
      // Selects the proper table
      .from('supplier_scope1_assessment')
      // Inserts values from form into table
      .insert({
            assessment_uuid: assessmentid,
            company_name: values.companyName,
            scope_1_emissions: values.scope1Emissions ? values.scope1Emissions : null,
            annual_scope_emissions: values.annualScopeEmissions,
            representational_year: values.representationalYear,
            calendar_or_fiscal_year: values.calendarOrFiscal,
            // approximate_annual_revenue: values.approximateAnnualRevenue,
            allocation_percentage: values.allocationPercentage ? values.allocationPercentage : null, 
        }, {returning: 'minimal'}
        // { onConflict: 'assessment_uuid' },
        // { ignoreDuplicates: true },
      )
    if (error) throw error;   
  } catch (error) {
    throw error;
  }
};
