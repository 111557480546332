import { supabase } from '../../../../Utilities/SupabaseClient';

// Stores user responses to assessment 10001 in the DB.
export const insertIntoDB = async (values, assessmentid) => {
  try {
    const { data, error } = await supabase
      .from('supplier_initial_assessment_1')
      // Using upsert to avoid duplicate rows in the DB table.
      // If the a row with the same assessment_id exist, then
      // that row is updated with the new values.
      .insert({
          assessment_uuid: assessmentid,
          company_name: values.companyName,
          climate_change_policy: values.climateChangePolicy,
          climate_change_policy_description:
            values.climateChangePolicyDescription,
          climate_team_responsible: values.climateTeamResponsible,
          climate_team_description: values.climateTeamDescription,
          currently_measure_emissions: values.currentlyMeasureEmissions,
          currently_measured_emissions_program_description:
            values.currentlyMeasuredEmissionsProgramDescription,
          scopes_measured_description: values.scopesMeasuredDescription,
          emissions_data_stored_description:
            values.emissionsDataStoredDescription,
        }, {returning: 'minimal'}
        // { onConflict: 'assessment_uuid' },
        // { ignoreDuplicates: true }
      )
    if (error) throw error;   
  } catch (err) {
    throw err;
  }
};
