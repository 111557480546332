const processSupplierData = (data) => {
  console.log(data);
  const cleanData = [];
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const emissionsCalc = calculateEmissionsNumbers(item['scope_1_emissions'], item['scope_2_emissions'], item['allocation_pct_scope_1'], item['allocation_pct_scope_2']);
    const itemToAdd = {
      id: item['id'],
      company_name: item['company_name'],
      // annual_spend: item['annual_expenditure'],
      scope_1: item['scope_1_emissions'],
      scope_2: item['scope_2_emissions'],
      // attribution_pct: emissionsCalc['attribution_pct'],
      attribution_pct_scope_1: item['allocation_pct_scope_1'],
      attribution_pct_scope_2: item['allocation_pct_scope_2'],
      attributed_scope_1: emissionsCalc['attributed_scope_1'],
      attributed_scope_2: emissionsCalc['attributed_scope_2'],
      scope_1_and_2_attributed_emissions: emissionsCalc['total_attributed_emissions']
    }
    cleanData.push(itemToAdd);
  }
  return cleanData;
}

// Returns object with calculated attribution numbers for emissions. We are
// allocating emissions to the user (company using our software) based on 
// the percentage of suppliers' revenue the company makes up.
const calculateEmissionsNumbers = (scope1emissions, scope2emissions, allocationPctScope1, allocationPctScope2) => {  
  // if (!annualspend || !supplierrevenue) return {
  //   attribution_pct: null,
  //   attributed_scope_1: null,
  //   attributed_scope_2: null,
  //   total_attributed_emissions: null
  // };
  
  // const attributionpct = annualspend / supplierrevenue; // Pct of emissions that from the supplier that should be attributed to the user
  // let totalattributedemissions = null;
  // if (scope1emissions && scope2emissions) totalattributedemissions = scope1emissions * (allocationPctScope1 / 100) + scope2emissions * (allocationPctScope2 / 100);
  
  let attributedScope1;
  if (scope1emissions === null || allocationPctScope1 === null) {
    attributedScope1 = null;
  } else {
    attributedScope1 = scope1emissions * (allocationPctScope1 / 100);
  }

  let attributedScope2;
  if (scope2emissions === null || allocationPctScope2 === null) {
    attributedScope2 = null;
  } else {
    attributedScope2 = scope2emissions * (allocationPctScope2 / 100);
  }

  let totalattributedemissions = null;
  if (attributedScope1 && attributedScope2) {
    totalattributedemissions = attributedScope1 + attributedScope2;
  } else if (attributedScope1 && !attributedScope2) {
    totalattributedemissions = attributedScope1;
  } else if (!attributedScope1 && attributedScope2) {
    totalattributedemissions = attributedScope2;
  }

  return {
    attribution_pct_scope_1: allocationPctScope1,
    attribution_pct_scope_2: allocationPctScope2,
    attributed_scope_1: attributedScope1,
    attributed_scope_2: attributedScope2,
    total_attributed_emissions: totalattributedemissions
  }
}

export default processSupplierData;