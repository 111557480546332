import { supabase } from '../../../../Utilities/SupabaseClient';
import getSupplierIDFromDB from '../../Utilities/getSupplierIDFromDB'

const updateSuppliersDB = async (values, assessmentid) => {
  const supplierUUID = await getSupplierIDFromDB(assessmentid);

  const { data, error } = await supabase
    .from('suppliers')
    .update(
      {
        scope_1_emissions: values.annualScopeEmissions,
      }
    )
    .eq('uuid', supplierUUID)
    .select();
};

export default updateSuppliersDB;