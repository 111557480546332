// Assessment 10006 has 2 block responses with multiple lines. This
// function prepares the string needed for those responses.
const getBlockResponses = (assessmentResponse) => {
  if (!assessmentResponse) return ['', ''];
  const location1 = assessmentResponse['location_1'] ? assessmentResponse['location_1'] : 'N/A';
  const location1kwh = assessmentResponse['location_1_kwh'] ? assessmentResponse['location_1_kwh'] + ' kWh' : 'N/A';
  const location2 = assessmentResponse['location_2'] ? assessmentResponse['location_2'] : 'N/A';
  const location2kwh = assessmentResponse['location_2_kwh'] ? assessmentResponse['location_2_kwh'] + ' kWh' : 'N/A';
  const location3 = assessmentResponse['location_3'] ? assessmentResponse['location_3'] : 'N/A';
  const location3kwh = assessmentResponse['location_3_kwh'] ? assessmentResponse['location_3_kwh'] + ' kWh' : 'N/A';
  const location4 = assessmentResponse['location_4'] ? assessmentResponse['location_4'] : 'N/A';
  const location4kwh = assessmentResponse['location_4_kwh'] ? assessmentResponse['location_4_kwh'] + ' kWh' : 'N/A';
  const location5 = assessmentResponse['location_5'] ? assessmentResponse['location_5'] : 'N/A';
  const location5kwh = assessmentResponse['location_5_kwh'] ? assessmentResponse['location_5_kwh'] + ' kWh' : 'N/A';
  const renewablelocation1 = assessmentResponse['location_1'] ? assessmentResponse['location_1'] : 'N/A';
  const renewablelocation1kwh = assessmentResponse['renewable_location_1_kwh'] ? assessmentResponse['renewable_location_1_kwh'] + ' kWh' : 'N/A';
  const renewablelocation2 = assessmentResponse['renewable_location_2'] ? assessmentResponse['renewable_location_2'] : 'N/A';
  const renewablelocation2kwh = assessmentResponse['renewable_location_2_kwh'] ? assessmentResponse['renewable_location_2_kwh'] + ' kWh' : 'N/A';
  const renewablelocation3 = assessmentResponse['renewable_location_3'] ? assessmentResponse['renewable_location_3'] : 'N/A';
  const renewablelocation3kwh = assessmentResponse['renewable_location_3_kwh'] ? assessmentResponse['renewable_location_3_kwh'] + ' kWh' : 'N/A';
  const renewablelocation4 = assessmentResponse['renewable_location_4'] ? assessmentResponse['renewable_location_4'] : 'N/A';
  const renewablelocation4kwh = assessmentResponse['renewable_location_4_kwh'] ? assessmentResponse['renewable_location_4_kwh'] + ' kWh' : 'N/A';
  const renewablelocation5 = assessmentResponse['renewable_location_5'] ? assessmentResponse['renewable_location_5'] : 'N/A';
  const renewablelocation5kwh = assessmentResponse['renewable_location_5_kwh'] ? assessmentResponse['renewable_location_5_kwh'] + ' kWh' : 'N/A';
  const firstBlock = `Location: ${location1}, Electricity: ${location1kwh} \nLocation: ${location2}, Electricity: ${location2kwh} \nLocation: ${location3}, Electricity: ${location3kwh} \nLocation: ${location4}, Electricity: ${location4kwh} \nLocation: ${location5}, Electricity: ${location5kwh}`;
  const secondBlock = `Location: ${renewablelocation1}, Electricity: ${renewablelocation1kwh} \nLocation: ${renewablelocation2}, Electricity: ${renewablelocation2kwh} \nLocation: ${renewablelocation3}, Electricity: ${renewablelocation3kwh} \nLocation: ${renewablelocation4}, Electricity: ${renewablelocation4kwh} \nLocation: ${renewablelocation5}, Electricity: ${renewablelocation5kwh}`;

  return [firstBlock, secondBlock];
}

export default getBlockResponses;