import { useState } from 'react';
import { Link } from 'react-router-dom';
import getAssessmentTitle from '../Utilities/getAssessmentTitle';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import getAssessmentStatusText from '../Utilities/getAssessmentStatusText';
import MaterialPopup from '../../../Components/Popup/MaterialPopup';
import sendAssessmentEmail from '../Utilities/sendAssessmentEmail';
import addToAssessmentsSentTable from '../Utilities/addToAssessmentsSentTable';
import styles from './AssessmentField.module.css';
import getAssessmentInfoFromDB from '../Utilities/getAssessmentsInfoFromDB';
import getAssessmentID from '../Utilities/getAssessmentID';

// This components renders the status and send button
// for a particular assessment.
const AssessmentField = ({
  toEmail,
  assessmentNumber,
  assessmentStatus,
  supplierName,
  supplierid,
  session,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [assessmentState, setAssessmentState] = useState(assessmentStatus);
  const assessmentTitle = getAssessmentTitle(assessmentNumber);
  const status = getAssessmentStatusText(assessmentState);

  const onClickBtn = async () => {
    setIsPopupOpen(true);
    try {
      if (assessmentState === 'not_sent') {
        // This block deals with the case of an assessment being sent for
        // the first time.
        await addToAssessmentsSentTable(
          supplierid,
          assessmentNumber,
          session.user.id
        );
  
        const sentAssessmentInfo = await getAssessmentInfoFromDB(supplierid);
        const assessmentID = sentAssessmentInfo[2].slice(-1);
  
        await sendAssessmentEmail(
          toEmail,
          supplierName,
          supplierid,
          session.user.email,
          assessmentNumber,
          assessmentID
        );
  
        setAssessmentState('sent');  
      } else {
        // This block deals with the case where the assessment has already been sent
        // and needs to be resent.
        const assessmentAlreadySentID = await getAssessmentID(supplierid, assessmentNumber);

        await sendAssessmentEmail(
          toEmail,
          supplierName,
          supplierid,
          session.user.email,
          assessmentNumber,
          assessmentAlreadySentID
        );
      }
    } catch (error) {
      console.log(error);
      // Do nothing with error
    }
  };

  let buttonText = 'send';
  if (assessmentState !== 'not_sent') {
    buttonText = 'resend';
  }

  return (
    <>
      <div className={styles.assessmentfieldwrapper}>
        <div className={styles.assessmentfieldheading}>
          <Link to={`/showassessment/${assessmentNumber}`}>
            {assessmentTitle}
          </Link>
        </div>
        <div className={styles.assessmentfieldvalue}>
          <span className={styles.assessmentfieldspan}>{status}</span>
          {/* {assessmentState === 'not_sent' && ( */}
            <button
              onClick={onClickBtn}
              type='button'
              className={styles.assessmentfieldbutton}
            >
              <div className={styles.assessmentfieldbuttoninside}>
                <div className={styles.assessmentfieldpencil}>
                  <SendOutlinedIcon style={{ fontSize: 15, color: '6b7dc2' }} />
                </div>
                <div className={styles.assessmentfieldtext}>{buttonText}</div>
              </div>
            </button>
          {/* )} */}
        </div>
      </div>
      <MaterialPopup
        isOpen={isPopupOpen}
        setOpenFunc={setIsPopupOpen}
        title='Assessment sent'
        dialogContent='The assessment has been sent by email to the supplier. Once a response has been received, we will aggregate the results.'
      />
    </>
  );
};

export default AssessmentField;
