import { supabase } from '../../../Utilities/SupabaseClient';
import { v4 as uuidv4 } from 'uuid';

// Adds the suppler information to the suppliers table in the DB.
export const insertIntoDB = async (uid, values) => {
  let result;
  const uuid = uuidv4();
  try {
    // Using code form from Supabase docs. 
    // https://supabase.com/docs/guides/database/tables 
    result = await supabase
    .from('suppliers')
    .insert([{
      user_added_supplier_id: uid,
      company_name: values.companyName,
      email: values.email,
      phone: values.phoneNumber,
      contact_first_name: values.firstName,
      contact_last_name: values.lastName,
      // supplier_status: 'no_assessments_sent',
      uuid: uuid,
      // assessments: assessments
    }]);
  } catch(e) {
    console.log(e);
    throw(e);
  }
  // Second error block here in case the catch block does not
  // recognize the error. Not sure how Supabase handles the error
  // message so including this here. May not be necessary.
  if (result && result.error) {
    throw (result.error);
  }
}