import { useContext, useState, useEffect, createContext } from 'react';
import { supabase } from '../Utilities/SupabaseClient';

// Create a context for authentication
const AuthContext = createContext();

// export the useAuth hook
export const useAuth = () => {
  return useContext(AuthContext);
};

const useProvideAuth = () => {
  // Setting the state of the session
  const [session, setSession] = useState(null);
  // // const [email, setEmail] = useState('');
  // const [user, setUser] = useState('');
  // const [showMagicLinkMsg, setMagicLinkMsg] = useState(false);

  const loginOTP = async (email) => {
    try {
      // setMagicLinkMsg(true); // This will show the user the message to check email inbox
      const { error } = await supabase.auth.signInWithOtp({
        email,
      });
      if (error) throw error;
      //alert('Check your email for the login link!');
    } catch (error) {
      if (error && error.message) alert(error.message);
      // setMagicLinkMsg(false);
    } finally {
      // setLoading(false)
    }
  };

  const register = async (email, password) => {
    // const { data, error } = await supabase.auth.signUp({
    //   email,
    //   password,
    // });
    await supabase.auth.signUp({
      email,
      password,
    });

  };

  const loginPassword = async (email, password, setterFn) => {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) setterFn('Incorrect login credentials')
    // setUser(data.user);
  };

  const signOut = () => {
    supabase.auth.signOut();
  };

  useEffect(() => {
    // functionality to grab current session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (_event === 'PASSWORD_RECOVERY') {
        setTimeout(() => {
          window.location.assign('https://app.earthscope.earth/newpassword');
        }, 500);
      }
    });
  }, []);

  return { register, loginOTP, loginPassword, signOut, session, setSession };
};

// AuthProvider wrapper using useProvideAuth as value for value
export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
