import { supabase } from '../../../Utilities/SupabaseClient';
import { FIRST_ASSESSMENT_NUMBER } from '../../../Utilities/Constants';


const getResponseCompletionDateFromDB = async (assessmentNumber, assessmentUUID) => {
	const DBTableTitles = [
		'supplier_initial_assessment_1',
		'supplier_initial_assessment_2',
		'supplier_scope1_assessment',
		'supplier_assessment_10004',
		'supplier_assessment_10005',
		'supplier_assessment_10006',
		'supplier_assessment_10007',
	];
  const idx = parseInt(assessmentNumber) - FIRST_ASSESSMENT_NUMBER;
  // console.log(DBTableTitles[idx]);

  const DBTableTitle = DBTableTitles[idx];

  try {
    const {data, err} = await supabase.from(DBTableTitle).select('*').eq('assessment_uuid', assessmentUUID);
    // Error block here in case the catch block does not
    // recognize the error. Not sure how Supabase handles the error
    // message so including this here. May not be necessary.
    if (err) throw (err);
    if (data.length === 0) {
      return null;
    }
    return data[data.length - 1]['created_at'];
  } catch(e) {
    console.log(e);
    throw(e);
  }
};

export default getResponseCompletionDateFromDB;
