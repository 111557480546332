import { Grid, TextField, MenuItem } from '@mui/material';
import styles from './selectInput.styles.module.css';

const SelectInput = ({
  inputLabel,
  name,
  onChange,
  onBlur,
  value,
  error,
  helperText,
  menuItemValue,
  menuItemValue2,
  menuItemValue3,
}) => {
  return (
    <>
      <div
        style={{
          margin: '0 0 8px 0',
          fontSize: '1.1rem',
          color: 'rgba(83 , 83, 83, 0.9)',
          maxWidth: '750px',
        }}
      >
        {inputLabel}
      </div>
      <Grid item xs={12}>
        <TextField
          className={styles.selectInput}
          select
          variant='filled'
          label='Select'
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          error={error}
          helperText={helperText}
          // sx={{ m: 1 }}
          defaultValue=''
        >
          <MenuItem value={menuItemValue} >
            {menuItemValue}
          </MenuItem>
          <MenuItem value={menuItemValue2} >
            {menuItemValue2}
          </MenuItem>
          {menuItemValue3 && <MenuItem value={menuItemValue3} >
            {menuItemValue3}
          </MenuItem>}
        </TextField>
      </Grid>
    </>
  );
};

export default SelectInput;
