import { useEffect, useState } from 'react';
import AssessmentResponseHeader from '../../../Components/Headers/AssessmentResponseHeader';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';
import getAssessmentResponse from '../Utilities/getAssessmentResponse';
import ResponseItem from '../Subcomponents/AssessmentResponseItem';
import ResponseDate from '../Subcomponents/ReponseDate';
import styles from '../AssessmentResponse.module.css';

// Shows the responses that a supplier gave to Assessment 10007.
const Assessment10007Response = ( {assessmentid} ) => {
  const title = getAssessmentTitle(10007);
  const [assessmentResponse, setAssessmentResponse] = useState(null);

  useEffect(() => {
    async function getResponseInfo() {
      const incomingResponse = await getAssessmentResponse('supplier_assessment_10007', assessmentid);
      setAssessmentResponse(incomingResponse);
    }
    getResponseInfo()
  }, [assessmentid]);

  // Blank component until assessmentResponse has a value from
  // the DB call.
  if (!assessmentResponse) return null;

  return(
    <main className={styles.assessmentresponsemain}>
      <div className={styles.assessmentresponsecontentwrapper}>
        <AssessmentResponseHeader title={title} />
        <ResponseItem 
          question='Company Name'
          answer={assessmentResponse['company_name']}         
        />
        <ResponseItem 
          question='Product Name'
          answer={assessmentResponse['product_name']}         
        />
        <ResponseItem 
          question='Product ID or Serial Number (If Applicable)'
          answer={assessmentResponse['product_id']}         
        />
        <ResponseItem 
          question='Lifecycle Emissions From This Product (kg CO2e)' 
          answer={assessmentResponse['emissions']}         
        />
        <ResponseDate 
          assessmentResponse={assessmentResponse} 
        />
      </div>
    </main>
  );
}

export default Assessment10007Response;