import { supabase } from '../../../Utilities/SupabaseClient';

// This function gets the suppliers from the DB. It should only get
// suppliers associated with the logged in user, given the RLS setup.
export const getEmissionsKPIFromDB = async () => {
  const {
    data: { user },
  } = await supabase.auth.getUser();

  try {
    const { data, err } = await supabase
      .from('suppliers')
      .select(
        'scope_1_emissions, scope_2_emissions, allocation_pct_scope_1, allocation_pct_scope_2'
      )
      .eq('user_added_supplier_id', user.id);
    // console.log(data);
    if (err) throw err;
    let emissionsTotal = 0;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item['scope_1_emissions'] && item['allocation_pct_scope_1']) {
        emissionsTotal += item['scope_1_emissions'] * (item['allocation_pct_scope_1'] / 100);
      }

      if (item['scope_2_emissions'] && item['allocation_pct_scope_2']) {
        emissionsTotal += item['scope_2_emissions'] * (item['allocation_pct_scope_2'] / 100);
      }
    }
    return emissionsTotal.toFixed(1);
  } catch (e) {
    console.log(e);
    throw e;
  }
};
