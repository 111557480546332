import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import greenCheckMark from '../../images/green-check-mark-circle-hd.png';

import styles from './assessmentConfirmationPage.module.css';

const AssessmentConfirmationPage = () => {
  return (
    <Box
      display='flex'
      width='100%'
      height='100vh'
      alignItems='center'
      justifyContent='center'
    >
      <Card
        sx={{
          minWidth: 575,
          minHeight: 500,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={greenCheckMark} alt='' className={styles.greenCheckMark} />
        <CardContent className={styles.cardContent}>
          <h2 className={styles.cardH2}>Thank You!</h2>
          <p className={styles.cardParagraph}>Your submission has been received</p>
        </CardContent>
        {/* <CardActions className={styles.cardActions}>
          <Button
            href='/dashboard'
            size='MEDIUM'
            variant='outlined'
            color='success'
          >
            RETURN TO DASHBOARD
          </Button>
        </CardActions> */}
      </Card>
    </Box>
  );
};

export default AssessmentConfirmationPage;
