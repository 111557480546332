import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SupplierInitialAssessment from '../SupplierAssessments/SupplierInitialAssessment/SupplierInitialAssessment';
import SupplierInitialAssessment2 from '../SupplierAssessments/SupplierInitialAssessment2/SupplierInitialAssessment2';
import SupplierInitialAssessment3 from '../SupplierAssessments/SupplierAssessment3/SupplierInitialAssessment3';
import SupplierAssessment10004 from '../SupplierAssessments/SupplierAssessment10004/SupplierAssessment10004';
import SupplierAssessment10005 from '../SupplierAssessments/SupplierAssessment10005/SupplierAssessment10005';
import SupplierAssessment10006 from '../SupplierAssessments/SupplierAssessment10006/SupplierAssessment10006';
import SupplierAssessment10007 from '../SupplierAssessments/SupplierAssessment10007/SupplierAssessment10007';

// This component determines the assessment to be shown
// based on the assessment number passed it. It then shows
// assessment. It is only used to show an assessment questions
// to the user (not the user's suppliers). The user's suppliers
// see an assessment through AssessmentSelector component.
const AssessmentSelectorForUser = () => {
  const params = useParams();
  const assessmentNumber = parseInt(params.assessmentnumber);

  return (
    <>
      {(assessmentNumber === 10001) && <SupplierInitialAssessment assessmentid={''} disableBtn={true} /> }
      {(assessmentNumber === 10002) && <SupplierInitialAssessment2 assessmentid={''} disableBtn={true} />}
      {(assessmentNumber === 10003) && <SupplierInitialAssessment3 assessmentid={''} disableBtn={true} />}
      {(assessmentNumber === 10004) && <SupplierAssessment10004 assessmentid={''} disableBtn={true} />}
      {(assessmentNumber === 10005) && <SupplierAssessment10005 assessmentid={''} disableBtn={true} />}
      {(assessmentNumber === 10006) && <SupplierAssessment10006 assessmentid={''} disableBtn={true} />}
      {(assessmentNumber === 10007) && <SupplierAssessment10007 assessmentid={''} disableBtn={true} />}
    </>
  );

}

export default AssessmentSelectorForUser;