import * as yup from 'yup';

// Returns the Yup validation string from each field type
// that is possible. This will be used in the MUI dialog
// box.
const getYupValiation = (fieldName) => {
  const phoneRegExp = 
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  switch (fieldName) {
    case 'company_name':
      return (yup.string().required("Required"));
    case 'email':
      return (yup.string().email("Invalid email").required("Required"));
    case 'contact_first_name':
      return (yup.string().required("Required"));
    case 'contact_last_name':
      return (yup.string().required("Required"));
    case 'phone':
      return (yup.string().matches(phoneRegExp, "Phone number is not valid"));
    default:
      return (yup.string().required("Required"));
  }
}

export default getYupValiation;