import { TextField, Box } from '@mui/material';

const LocationInput = ({
  label,
  type,
  name,
  onChange,
  onBlur,
  error,
  helperText,
  label2,
  type2,
  name2,
  onBlur2,
  onChange2,
  error2,
  helperText2
}) => {
  return (
    <>
      <Box>
        <TextField
          variant='filled'
          label={label}
          type={type}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          error={error}
          helperText={helperText}
          sx={{mr: 2, width: 240}}
        />
        <TextField
          variant='filled'
          label={label2}
          type={type2}
          name={name2}
          onBlur={onBlur2}
          onChange={onChange2}
          error={error2}
          helperText={helperText2}
          sx={{width: 240}}
        />
        <Box height={14}/>
      </Box>
    </>
  );
};

export default LocationInput;