import { supabase } from '../../../../Utilities/SupabaseClient';

// Updates the DB assessments_sent table to record that a
// response has been received.
const setAssessmentResponseField = async (assessmentid) => {
  try {
    const { error } = await supabase
      .from('assessments_sent')
      .update({ response: true})
      .eq('assessment_uuid', assessmentid);
    if (error) throw error;
  } catch (err) {
    console.log('Error message', err);
    throw err;
  }
}

export default setAssessmentResponseField;