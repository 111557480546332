import { useEffect, useState } from 'react';
import AssessmentResponseHeader from '../../../Components/Headers/AssessmentResponseHeader';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';
import getAssessmentResponse from '../Utilities/getAssessmentResponse';
import ResponseItem from '../Subcomponents/AssessmentResponseItem';
import ResponseDate from '../Subcomponents/ReponseDate';
import styles from '../AssessmentResponse.module.css';

// Shows the responses that a supplier gave to Assessment10002.
const Assessment10002Response = ( {assessmentid} ) => {
  const title = getAssessmentTitle(10002);
  const [assessmentResponse, setAssessmentResponse] = useState(null);

  useEffect(() => {
    async function getResponseInfo() {
      const incomingResponse = await getAssessmentResponse('supplier_initial_assessment_2', assessmentid);
      setAssessmentResponse(incomingResponse);
    }
    getResponseInfo()

  }, [assessmentid]);

  // Blank component until assessmentResponse has a value from
  // the DB call.
  if (!assessmentResponse) return null;

  return(
    <main className={styles.assessmentresponsemain}>
      <div className={styles.assessmentresponsecontentwrapper}>
        <AssessmentResponseHeader title={title} />
        <ResponseItem 
          question='Company Name'
          answer={assessmentResponse['company_name']}         
        />
        <ResponseItem 
          question='Climate reduction targets in place?'
          answer={assessmentResponse['climate_reduction_target_in_place']}         
        />
        <ResponseItem 
          question='Describe your current targets.'
          answer={assessmentResponse['climate_reduction_target_description']}         
        />
        <ResponseItem 
          question='Does your company buy carbon offsets or participate in offset programs?'
          answer={assessmentResponse['offset_programs']}         
        />
        <ResponseItem 
          question='Describe your offset programs.'
          answer={assessmentResponse['offset_program_description']}         
        />
        <ResponseItem 
          question='Does the company assess climate risks to its business?'
          answer={assessmentResponse['climate_risk_assessment']}         
        />
        <ResponseItem 
          question='Describe the current climate related risks to your business.'
          answer={assessmentResponse['current_climate_risk_description']}         
        />
        <ResponseItem 
          question='Are there any material contracts or operations which the company considers 
          to be not particular environmentally friendly? Respond with "No answer" if you prefer not to answer.'
          answer={assessmentResponse['material_contracts_or_operations']}         
        />
        <ResponseItem 
          question='Describe these contracts or operations.'
          answer={assessmentResponse['material_contracts_description']}         
        />
        <ResponseDate 
          assessmentResponse={assessmentResponse} 
        />

      </div>
    </main>
  );
}

export default Assessment10002Response;