import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import getAssessmentNumberFromDB from './Utilities/getAssessmentNumberFromDB';
import SupplierInitialAssessment from '../SupplierAssessments/SupplierInitialAssessment/SupplierInitialAssessment';
import SupplierInitialAssessment2 from '../SupplierAssessments/SupplierInitialAssessment2/SupplierInitialAssessment2';
import SupplierInitialAssessment3 from '../SupplierAssessments/SupplierAssessment3/SupplierInitialAssessment3';
import SupplierAssessment10004 from '../SupplierAssessments/SupplierAssessment10004/SupplierAssessment10004';
import SupplierAssessment10005 from '../SupplierAssessments/SupplierAssessment10005/SupplierAssessment10005';
import SupplierAssessment10006 from '../SupplierAssessments/SupplierAssessment10006/SupplierAssessment10006';
import SupplierAssessment10007 from '../SupplierAssessments/SupplierAssessment10007/SupplierAssessment10007';

// This component determines the assessment to be shown
// based on the assessment uuid. It then shows that assessment.
const AssessmentSelector = () => {
  const [assessmentNumber, setAssessmentNumber] = useState(0);
  const params = useParams();

  useEffect(() => {
    async function getAssessmentNumber() {
      const dbReturn = await getAssessmentNumberFromDB(params.assessmentid);
      if (dbReturn.length > 0) setAssessmentNumber(dbReturn[0]['assessment_number']);
    }
    getAssessmentNumber();
  }, [params.assessmentid]);
  return (
    <>
      {(assessmentNumber === 10001) && <SupplierInitialAssessment assessmentid={params.assessmentid} disableBtn={false} /> }
      {(assessmentNumber === 10002) && <SupplierInitialAssessment2 assessmentid={params.assessmentid} disableBtn={false} />}
      {(assessmentNumber === 10003) && <SupplierInitialAssessment3 assessmentid={params.assessmentid} disableBtn={false} />}
      {(assessmentNumber === 10004) && <SupplierAssessment10004 assessmentid={params.assessmentid} disableBtn={false} />}
      {(assessmentNumber === 10005) && <SupplierAssessment10005 assessmentid={params.assessmentid} disableBtn={false} />}
      {(assessmentNumber === 10006) && <SupplierAssessment10006 assessmentid={params.assessmentid} disableBtn={false} />}
      {(assessmentNumber === 10007) && <SupplierAssessment10007 assessmentid={params.assessmentid} disableBtn={false} />}
    </>
  );

}

export default AssessmentSelector;