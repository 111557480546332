import { supabase } from '../../../../Utilities/SupabaseClient';

export const insertIntoDB = async (values, assessmentid) => {
  try {
    const { error } = await supabase
      // Selects the proper table
      .from('supplier_assessment_10006')
      // Inserts values from form into table
      .insert(
          {
            assessment_uuid: assessmentid,
            company_name: values.companyName ? values.companyName : null, // If we upsert with undefined, Postgres doesn't change the record value. Using null.
            location_1: values.location1 ? values.location1 : null,
            location_1_kwh: values.location1kwh ? values.location1kwh : null,
            location_2: values.location2 ? values.location2 : null,
            location_2_kwh: values.location2kwh ? values.location2kwh: null,
            location_3: values.location3 ? values.location3 : null,
            location_3_kwh: values.location3kwh ? values.location3kwh : null,
            location_4: values.location4 ? values.location4 : null,
            location_4_kwh: values.location4kwh ? values.location4kwh: null,
            location_5: values.location5 ? values.location5 : null,
            location_5_kwh: values.location5kwh ? values.location5kwh : null,
            other_electricity: values.otherelectricity ? values.otherelectricity : null,
            representational_year: values.representationalYear ? values.representationalYear: null,
            calendar_or_fiscal: values.calendarOrFiscal ? values.calendarOrFiscal : null,
            renewable_location_1: values.renewablelocation1 ? values.renewablelocation1 : null,
            renewable_location_1_kwh: values.renewablelocation1kwh ? values.renewablelocation1kwh : null,
            renewable_location_2: values.renewablelocation2 ? values.renewablelocation2: null,
            renewable_location_2_kwh: values.renewablelocation2kwh ? values.renewablelocation2kwh : null,
            renewable_location_3: values.renewablelocation3 ? values.renewablelocation3 : null,
            renewable_location_3_kwh: values.renewablelocation3kwh ? values.renewablelocation3kwh : null,
            renewable_location_4: values.renewablelocation4 ? values.renewablelocation4 : null,
            renewable_location_4_kwh: values.renewablelocation4kwh ? values.renewablelocation4kwh : null,
            renewable_location_5: values.renewablelocation5 ? values.renewablelocation5 : null,
            renewable_location_5_kwh: values.renewablelocation5kwh ? values.renewablelocation5kwh: null,
            other_renewable: values.otherrenewable ? values.otherrenewable : null,
            other_scope_2_energy_purchases: values.otherscope2energypurchases ? values.otherscope2energypurchases: null,
            other_scope_2: values.otherscope2 ? values.otherscope2 : null,
            allocation_percentage: values.allocationpercentage ? values.allocationpercentage : null,
          }, { returning: 'minimal'}
          // { onConflict: 'assessment_uuid' },
          // { ignoreDuplicates: true }
      )
      if (error) console.log(error);
      if (error) throw (error);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
