import { supabase } from '../../../../Utilities/SupabaseClient';

export const insertIntoDB = async (values, assessmentid) => {
  try {
    const { error } = await supabase
      // Selects the proper table
      .from('supplier_assessment_10005')
      // Inserts values from form into table
      .insert(
          {
            assessment_uuid: assessmentid,
            company_name: values.companyName ? values.companyName : null, // If we upsert with undefined, Postgres doesn't change the record value. Using null.
            gasoline_cars: values.gasolinecars ? values.gasolinecars : null,
            gasoline_trucks: values.gasolinetrucks ? values.gasolinetrucks : null,
            diesel_trucks: values.dieseltrucks ? values.dieseltrucks : null,
            diesel_other: values.dieselother ? values.dieselother : null,
            biodiesel: values.biodiesel ? values.biodiesel : null,
            anthracite: values.anthracite ? values.anthracite : null,
            brown_coal: values.browncoal ? values.browncoal : null,
            coking_coal: values.cokingcoal ? values.cokingcoal : null,
            crude_oil: values.crudeoil ? values.crudeoil :null,
            natural_gas: values.naturalgas ? values.naturalgas : null,
            kerosene_jet_fuel: values.kerosenejetfuel ? values.kerosenejetfuel : null,
            jet_gasoline: values.jetgasoline ? values.jetgasoline : null,
            ethane: values.ethane ? values.ethane : null,
            propane: values.propane ? values.propane : null,
            biogas: values.biogas ? values.biogas : null,
            other_combustion: values.othercombustion ? values.othercombustion : null,
            specific_industry: values.specificindustry ? values.specificindustry : null,
            representational_year: values.representationalyear ? values.representationalyear : null,
            calendar_or_fiscal: values.calendarorfiscal ? values.calendarorfiscal : null,
            allocation_percentage: values.allocationpercentage ? values.allocationpercentage : null,
          }, { returning: 'minimal'}
          // { onConflict: 'assessment_uuid' },
          // { ignoreDuplicates: true }
      )
      if (error) console.log(error);
      if (error) throw (error);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
