import { useNavigate } from 'react-router-dom';
import { Grid, Box, Button } from '@mui/material';

import { Formik } from 'formik';
import * as yup from 'yup';

import AssessmentHeader from '../../../Components/Headers/AssessmentHeader';
import OneLineInput from '../../../Components/OneLineInput/OneLineInput';
import SelectInput from '../../../Components/SelectInput/SelectInput';
import { insertIntoDB } from './Utilities/insertIntoDB';
import setAssessmentResponseField from '../SupplierInitialAssessment/Utilities/setAssessmentResponseField';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';
import styles from '../SupplierInitialAssessment.module.css';
import GoBackLink from '../Subcomponents/GoBackLink';

import updateSuppliersDB from './Utilities/updateSuppliersDB';
import OneLineInputNumeric from '../../../Components/OneLineInput/OneLineInputNumeric';

// Begin assessment from component
const SupplierAssessment10004 = ({ assessmentid, disableBtn }) => {
  // Gets assessment title
  const title = getAssessmentTitle(10004);
  const navigate = useNavigate();
  // Initial form values set to empty strings
  const initialValues = {
    companyName: '',
    scope2Emissions: '',
    annualScopeEmissions: undefined,
    representationalYear: '2023',
    calendarOrFiscal: 'CY',
    allocationPercentage: undefined,
  };

  // Validates inputs for required fields
  const validateSchema = yup.object().shape({
    companyName: yup.string().required('Required'),
    scope2Emissions: yup.string().ensure().required('Please select an answer'),
    annualScopeEmissions: yup.number().when('scope2Emissions', {
      is: 'Yes',
      then: yup
        .number()
        .required('Please enter in a number.')
        .min(0, 'Must be a positive number.'),
    }),
    representationalYear: yup.string().when('scope2Emissions', {
      is: 'Yes',
      then: yup
        .string()
        // .positive('Please enter a year.')
        .required('Please choose a year.'),
    }),
    calendarOrFiscal: yup
      .string()
      .ensure()
      .when('scope2Emissions', {
        is: 'Yes',
        then: yup.string().required('Please select calendar or fiscal.'),
      }),
    // approximateAnnualRevenue: yup.number().when('scope2Emissions', {
    //   is: 'Yes',
    //   then: yup.number(),
    //   // .positive('Must be a positive number.'),
    // }),
    allocationPercentage: yup.number().when('scope2Emissions', {
      is: 'Yes',
      then: yup.number().required('Please enter a number.').min(0, 'Must be a positive number.').max(100, 'Must be less than 100.'),
    }),
  });

  // Inserts data into the database when submit button is clicked
  const handleFormSubmit = (values, { resetForm }) => {
    try {
      if (!disableBtn) {
        insertIntoDB(values, assessmentid);
        setAssessmentResponseField(assessmentid);
        updateSuppliersDB(values, assessmentid);
      }
      resetForm(initialValues);
      navigate('/confirmation');
      console.log(values);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <main className={styles.initialAssessmentMain}>
        <Box mt='20px'>
          <GoBackLink />
          <AssessmentHeader title={title} />
          <Grid container direction='row' alignItems='center'>
            <div>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validateSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      display='grid'
                      gap='30px'
                      gridTemplateColumns='repeat(1, minmax(0, 1fr))'
                    >
                      <div>
                        <OneLineInput
                          inputLabel='Company Name'
                          label='Your Company Name'
                          type='text'
                          name='companyName'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={!!touched.companyName && !!errors.companyName}
                          helperText={touched.companyName && errors.companyName}
                        />
                      </div>

                      <div>
                        <SelectInput
                          inputLabel='Does your company know it’s Scope 2 emissions?'
                          name='scope2Emissions'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.scope2Emissions}
                          error={
                            !!touched.scope2Emissions &&
                            !!errors.scope2Emissions
                          }
                          helperText={
                            touched.scope2Emissions && errors.scope2Emissions
                          }
                          menuItemValue='Yes'
                          menuItemValue2='No'
                        />
                        {values.scope2Emissions === 'Yes' ? (
                          <>
                            <Box
                              mt='30px'
                              display='grid'
                              gap='30px'
                              gridTemplateColumns='repeat(1, minmax(0, 1fr))'
                            >
                              <div>
                                <OneLineInputNumeric
                                  inputLabel='What are your annual Scope 2 emissions (metric tons of CO2 equivalent)?'
                                  type='number'
                                  name='annualScopeEmissions'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={
                                    !!touched.annualScopeEmissions &&
                                    !!errors.annualScopeEmissions
                                  }
                                  helperText={
                                    touched.annualScopeEmissions &&
                                    errors.annualScopeEmissions
                                  }
                                />
                              </div>
                              <div>
                                <SelectInput
                                  inputLabel='Which year does this represent?'
                                  type='number'
                                  name='representationalYear'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.representationalYear}
                                  error={
                                    !!touched.representationalYear &&
                                    !!errors.representationalYear
                                  }
                                  helperText={
                                    touched.representationalYear &&
                                    errors.representationalYear
                                  }
                                  // menuItemValue='2022'
                                  menuItemValue2='2023'
                                  // menuItemValue3='2024'
                                />
                              </div>

                              <div>
                                <SelectInput
                                  inputLabel='Reporting year'
                                  name='calendarOrFiscal'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.calendarOrFiscal}
                                  error={
                                    !!touched.calendarOrFiscal &&
                                    !!errors.calendarOrFiscal
                                  }
                                  helperText={
                                    touched.calendarOrFiscal &&
                                    errors.calendarOrFiscal
                                  }
                                  menuItemValue='CY'
                                  // menuItemValue2='FY'
                                />
                              </div>

                              <div>
                                <OneLineInputNumeric
                                  inputLabel='What percentage of your company&#39;s Scope 2 emissions should be allocated to this customer? The most common method is to determine how much of your total revenue comes from the customer. For example, if you have $100,000 in revenue and $4,500 comes from the customer, then enter 4.5 here. Another way is to determine how much of your total emissions are related to the customer, based on business activities. Enter the percentage as a number without a percent sign (i.e. for 15% enter 15).' 
                                  type='number'
                                  name='allocationPercentage'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={
                                    !!touched.allocationPercentage &&
                                    !!errors.allocationPercentage
                                  }
                                  helperText={
                                    touched.allocationPercentage &&
                                    errors.allocationPercentage
                                  }
                                />
                              </div>
                            </Box>
                          </>
                        ) : null}
                      </div>
                    </Box>
                    <Box display='flex' sx={{ m: '25px 0px 50px 0px' }}>
                      <Button
                        type='submit'
                        color='secondary'
                        variant='contained'
                      >
                        Submit Form
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </div>
          </Grid>
        </Box>
      </main>
    </>
  );
};

export default SupplierAssessment10004;
