import { supabase } from '../../../../Utilities/SupabaseClient';

export const insertIntoDB = async (values, assessmentid) => {
  try {
    const { error } = await supabase
      // Selects the proper table
      .from('supplier_assessment_10007')
      // Inserts values from form into table
      .insert(
          {
            assessment_uuid: assessmentid,
            company_name: values.companyName ? values.companyName : null,
            product_name: values.productName ? values.productName : null,
            product_description: values.productDescription ? values.productDescription : null,
            product_id: values.productId ? values.productId : null,
            emissions: values.emissions ? values.emissions : null,
          }, 
          {returning: 'minimal'}
      )
      if (error) console.log(error);
      if (error) throw (error);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
