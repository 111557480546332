// Converts the field name code to the field name displayed to the 
// user. _ chararcters are replaced by spaces and words are 
// capitalized.
const getEditableFieldName = (inputName) => {
  const fieldNameWithoutCases = inputName.replaceAll('_', ' ');
  const fieldName = fieldNameWithoutCases.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
  return fieldName;
}

export default getEditableFieldName;