import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import getSupplierInfoFromDB from './Utilities/getSupplierInfoFromDB';
import getAssessmentsInfoFromDB from './Utilities/getAssessmentsInfoFromDB';
import TopBar from '../../NavBars/TopBar';
import { Box } from '@mui/material';
import Header from '../../Components/Headers/Header';
import SupplierInfoEditableField from './Subcomponents/SupplerInfoEditableField';
import AssessmentField from './Subcomponents/AssessmentField';
import { supabase } from '../../Utilities/SupabaseClient';
import styles from './SupplierPage.module.css';
import { NUMBER_OF_ASSESSMENTS } from '../../Utilities/Constants';
import SidebarPanel from '../../NavBars/SideBarPanel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';



// This page shows information for a specific supplier and allows the
// user to edit that information.
const SupplierPage = () => {
	const params = useParams();
	const initialState = {
		company_name: '',
		contact_first_name: '',
		contact_last_name: '',
		email: '',
		phone: '',
	};
	const keys = Object.keys(initialState);
	const [supplierInfo, setSupplierInfo] = useState(initialState);
	const [assessments, setAssessments] = useState([]);
	const [session, setSession] = useState(null);

	useEffect(() => {
		supabase.auth.getSession().then(({ data: { session } }) => {
			setSession(session);
		});

		supabase.auth.onAuthStateChange((_event, session) => {
			setSession(session);
		});
	}, []);

	useEffect(() => {
		// Pulling in supplier info from Postgres DB.
		async function getSupplierInfo() {
			const incomingSupplierData = await getSupplierInfoFromDB(
				params.supplierid
			);
			const newSupplierInfo = {
				company_name: incomingSupplierData['company_name'],
				contact_first_name: incomingSupplierData['contact_first_name'],
				contact_last_name: incomingSupplierData['contact_last_name'],
				email: incomingSupplierData['email'],
				phone: incomingSupplierData['phone'],
			};
			setSupplierInfo(newSupplierInfo);
			// setAssessments(incomingSupplierData['assessments']);
		}
		getSupplierInfo();
	}, [params.supplierid]);

	useEffect(() => {
		// Pulling in assessments sent info from DB.
		async function getAssessmentInfo() {
			const incomingAssessmentsInfo = await getAssessmentsInfoFromDB(
				params.supplierid
			);
			const assessmentsSent = incomingAssessmentsInfo[0];
			const assessmentsWithResponse = incomingAssessmentsInfo[1];
			const assessmentsUUID = incomingAssessmentsInfo[2];
			let assessmentsArr = [];
			// This loop creates an array of all assessments. This will later
			// be used the create an array of AssessmentField objects to render.
			for (let i = 0; i < NUMBER_OF_ASSESSMENTS; i++) {
				const assessmentNumber = 10001 + i;
				let assessmentStatus = 'not_sent';
				let assessmentUUID = null;
				if (assessmentsSent.includes(assessmentNumber))
					assessmentStatus = 'sent';

				const index = assessmentsSent.indexOf(assessmentNumber);
				assessmentUUID = assessmentsUUID[index];

				if (assessmentsWithResponse.includes(assessmentNumber))
					assessmentStatus = 'response_received';
				assessmentsArr.push({
					assessment_number: assessmentNumber,
					status: assessmentStatus,
					uuid: assessmentUUID,
				});
			}
			setAssessments(assessmentsArr);
		}
		getAssessmentInfo();
	}, [params.supplierid]);

	const initialStateStr = localStorage.getItem('checked_state');
	let stateToUse = undefined;
	if (initialStateStr === 'true') stateToUse = true;
	else if (initialStateStr === 'false') stateToUse = false;

	const [checked, setChecked] = useState(stateToUse);
	const [newStatus, setNewStatus] = useState(false);

	useEffect(() => {
		const savedCheckedState = localStorage.getItem('checked_state');
		if (savedCheckedState === 'true') {
			setChecked(true);
			setNewStatus(true);
		} else if (savedCheckedState === 'false') {
			setChecked(false);
			setNewStatus(false);
		}
	}, []);

	// useEffect(() => {
	// 	(async () => {
	// 		try {
	// 			await fetch('http://localhost:8000/start-cron', {
	// 				method: 'POST',
	// 				headers: { 'Content-Type': 'application/json' },
	// 				body: JSON.stringify({
	// 					newStatus: newStatus,
	// 					jobId: params.supplierid,
	// 					supplierID: params.supplierid,
	// 					supplierName: supplierInfo['company_name'],
	// 					userEmail: session.user.email,
	// 					toEmail: supplierInfo['email'],
	// 					sendingUserID: session.user.id,
	// 				}),
	// 			}).then((response) => {
	// 				if (response.ok) {
	// 					console.log('Cron job status updated successfully');
	// 				} else {
	// 					console.log('Failed to update cron job status');
	// 				}
	// 			});
	// 		} catch (error) {
	// 			console.log('Error starting cron job: ', error);
	// 		}
	// 	})();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [newStatus]);

	const handleSwitch = async (e) => {
		// e.preventDefault();
		const isChecked = e.target.checked;
		setChecked(isChecked);
		localStorage.setItem('checked_state', isChecked.toString());
		setNewStatus(e.target.checked);
		setNewStatus(!checked);
		// localStorage.setItem('checked_state', (!checked).toString());
		// setChecked(!checked);
	};

	// Supplier status should not be an editable field. Removing
	// it with filter.
	// const adjustedKeys = keys.filter((item) => {
	//   return item !== 'supplier_status'
	// });
	const editableFields = keys.map((key, index) => {
		return (
			<SupplierInfoEditableField
				key={index.toString()}
				fieldName={key}
				fieldValue={supplierInfo[key]}
				setterFn={setSupplierInfo}
				currentSupplierInfo={supplierInfo}
				supplierid={params.supplierid}
			/>
		);
	});

	const assessmentFields = assessments.map((item, index) => {
		return (
			<AssessmentField
				key={index.toString()}
				assessmentNumber={item['assessment_number']}
				assessmentStatus={item['status']}
				supplierName={supplierInfo['company_name']}
				toEmail={supplierInfo['email']}
				supplierid={params.supplierid}
				session={session}
			/>
		);
	});

	console.log(checked);

	return (
		<>
			<SidebarPanel />
			<main className={styles.supplierpagemain}>
				<nav>
					<TopBar />
				</nav>
				<Box m='20px'>
					<Header
						title='SUPPLIER ASSESSMENTS & INFORMATION'
						subtitle={supplierInfo['company_name']}
					/>
				</Box>
				<div className={styles.supplierpageassessmentstitle}>Assessments</div>
				{/* <div className={styles.switch_container}>
          <div className={styles.switch_text}>
            <h3>Send Assessments Automatically</h3>
          </div>
					<div className={styles.switch_button_container}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Off</Typography>
                <Switch
                  checked={checked}
                  onChange={handleSwitch}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              <Typography>On</Typography>
            </Stack>
					</div>
				</div> */}
				{session && (
					<div className={styles.supplierpageassessmentswrapper}>
						{assessmentFields}
					</div>
				)}
				<div className={styles.supplierpagesititle}>Supplier Information</div>
				<div className={styles.supplierpageeditableswrapper}>
					{editableFields}
				</div>
			</main>
		</>
	);
};

export default SupplierPage;
