import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import saveSupplierInfoChange from '../../MainPages/SupplierPage/Utilities/saveSupplierInfoChange';

// Renders at Material UI popup. isOpen controls whether the popup
// is open. setOpenFunc is the setter function that can toggle the 
// popup open and closed.
const MaterialPopupWithInput = ({isOpen, setOpenFunc, titleField, yupValidation, fieldNameForDB, 
  currentSupplierInfo, setterFn, supplierid}) => {
  // const [fieldValue, setFieldValue] = useState('');
  // const [errorText, setErrorText] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const titleCaseAdjusted = titleField.toLowerCase();
  let textFieldType = 'text';
  if (titleField && titleField === 'Email') textFieldType = 'email';

  const handleClickCancel = () => {
    setIsDisabled(true);
    setOpenFunc(false); // Close dialog (in upstream component)
    setIsDisabled(false);
  }

  const handleFormSubmit = async (values) => {
    setIsDisabled(true);
    try {
      await saveSupplierInfoChange(fieldNameForDB, values.inputField, supplierid);
    } catch (error) {
      // Do nothing
    }
    // Update the upstream component state with the changed
    // supplier info.
    setterFn({
      ...currentSupplierInfo,
      [fieldNameForDB]: values.inputField
    });
    setIsDisabled(false);
    setOpenFunc(false); // Close dialog (in upstream component)
  }

  const validationSchema = yup.object().shape({
    inputField: yupValidation
  });

  const initialValues = {
    inputField: '' 
  }

  return(
    <Dialog 
      open={isOpen}
      fullWidth={true}
      maxWidth='sm'
    >
      <DialogTitle fontSize="18px" fontWeight="600">
        {titleField}
      </DialogTitle>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  margin='dense'
                  label={`Enter the ${titleCaseAdjusted}`}
                  type={textFieldType}
                  fullWidth
                  variant='standard'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.inputField}
                  name='inputField'
                  error={!!touched.inputField && !!errors.inputField}
                  helperText={touched.inputField && errors.inputField}
                />
              </DialogContent>
              <DialogActions>
                <Button 
                  type='submit'
                  variant="text"
                  size="medium"
                  sx={{
                    color: '#1976d2',
                    fontSize: '17px', 
                    fontWeight:'600',
                    ':hover': {
                      bgcolor: '#ebf2fa' 
                    }
                  }}
                  autoFocus
                  disabled={isDisabled}
                >
                  Save
                </Button>
                <Button 
                  variant="text"
                  size="medium"
                  sx={{
                    color: '#1976d2',
                    fontSize: '17px', 
                    fontWeight:'600',
                    ':hover': {
                      bgcolor: '#ebf2fa' 
                    }
                  }}
                  onClick={handleClickCancel}
                  disabled={isDisabled}
                >
                  Cancel
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
        {/* <Typography
          variant="h5"
        >
          {dialogContent}
        </Typography> */}
    </Dialog>
  );
}

export default MaterialPopupWithInput;