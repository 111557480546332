import ResponseItem from "./AssessmentResponseItem";

// This component shows the date that a supplier completed an 
// assessment.
const ResponseDate = ({assessmentResponse}) => {
  if (!assessmentResponse) return null;
  const dateSubmitted = (new Date(assessmentResponse['created_at'])).toString();
  const splitDate = dateSubmitted.split(' ');

  return(
    <ResponseItem 
      question='Date completed:'
      answer={splitDate[1] + ' ' + splitDate[2] + ', ' + splitDate[3]}    
    />
  );
}

export default ResponseDate;