import { supabase } from '../../../Utilities/SupabaseClient';

// Gets the response to an assessment from the DB.
const getAssessmentResponse = async (tablename, assessmentid) => {
  const {data, err} = await supabase.from(tablename).select('*').eq('assessment_uuid', assessmentid);
  // Error block here in case the catch block does not
  // recognize the error. Not sure how Supabase handles the error
  // message so including this here. May not be necessary.
  try {
    if (err) throw (err);
    if (data) {
      // Sort the responses by date
      const sortedData = data.toSorted((a, b) => {
        // Sorts by dates/times in ascending order
        return (new Date(a['created_at']) - new Date(b['created_at']));
      });
      console.log(sortedData);
      // Return the most recent response
      return (sortedData[sortedData.length - 1]);
    }
  } catch(e) {
    console.log(e);
    throw(e);
  }
}

export default getAssessmentResponse;