import { supabase } from '../../../Utilities/SupabaseClient';

// Returns the ID for an assessment that has already been sent.
// This function is used when an assessment needs to be resent.
const getAssessmentID = async (supplierid, assessmentNumber) => {
  try {  
    const {data, err} = await supabase
                        .from('assessments_sent')
                        .select('assessment_uuid').eq('supplier_uuid', supplierid)
                        .eq('assessment_number', parseInt(assessmentNumber));
    // Error block here in case the catch block does not
    // recognize the error. Not sure how Supabase handles the error
    // message so including this here. May not be necessary.
    if (err) throw (err);
    if (data) {
      if (data.length < 1) return (null);
      return (data[0]['assessment_uuid']);
    } else {
      return (null);
    }
  } catch(e) {
    console.log(e);
    throw(e);
  }
}

export default getAssessmentID;