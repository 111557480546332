import { supabase } from '../../../../Utilities/SupabaseClient';

export const insertIntoDB = async (values, assessmentid) => {
  try {
    const { error } = await supabase
      // Selects the proper table
      .from('supplier_initial_assessment_2')
      // Inserts values from form into table
      .insert(
          {
            assessment_uuid: assessmentid,
            company_name: values.companyName,
            climate_reduction_target_in_place:
              values.climateReductionTargetInPlace,
            climate_reduction_target_description:
              values.climateReductionTargetDescription,
            offset_programs: values.offSetPrograms,
            offset_program_description: values.offsetProgramDescription,
            climate_risk_assessment: values.climateRiskAssessment,
            current_climate_risk_description:
              values.currentClimateRiskDescription,
            material_contracts_or_operations:
              values.materialContractsOrOperations,
            material_contracts_description: values.materialContractsDescription,
          }, {returning: 'minimal'}
          // { onConflict: 'assessment_uuid' },
          // { ignoreDuplicates: true }
      );
  } catch (error) {
    throw error;
  }
};
