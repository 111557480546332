import { useState } from 'react';
import getEditableFieldName from '../Utilities/getEditableFieldName';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styles from './SupplierInfoEditableField.module.css';
import MaterialPopupWithInput from '../../../Components/Popup/MaterialPopupWithInput';
import getYupValidation from '../Utilities/getYupValidation';

// This component renders one information field for a supplier.
// If the user edits the field, the MaterialPopupWithInput
// component called here allows that to happen.
const SupplierInfoEditableField = ({fieldName, fieldValue, setterFn, currentSupplierInfo, supplierid}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const fieldNameToUse = getEditableFieldName(fieldName);
  const yupValidation = getYupValidation(fieldName);
  
  const onClickEdit = () => {
    setIsPopupOpen(true);
  }

  return (
    <>
      <div className={styles.supplierinfoeditablefieldwrapper}>
        <div className={styles.supplierinfoeditablefieldheading}>
          {fieldNameToUse}
        </div>
        <div className={styles.supplierinfoeditablefieldvalue}>
          <span className={styles.supplierinfoeditablefieldspan}>{fieldValue}</span>
          <button type="button" className={styles.supplierinfoeditablefieldbutton} onClick={onClickEdit}>
            <div className={styles.supplierinfoeditablefieldbuttoninside}>
              <div className={styles.supplierinfoeditablefieldpencil}>
                <EditOutlinedIcon style={{ fontSize: 15, color: '0a8080' }}/>
              </div>
              <div className={styles.supplierinfoeditablefieldtext}>
                edit
              </div>
            </div>
          </button>
        </div>
      </div>
      <MaterialPopupWithInput
        isOpen={isPopupOpen}
        setOpenFunc={setIsPopupOpen}
        titleField={fieldNameToUse}
        yupValidation={yupValidation}
        fieldNameForDB={fieldName}
        currentSupplierInfo={currentSupplierInfo} // Supplier info passed down from upstream
        setterFn={setterFn} // Used to update supplier info in upstream component
        supplierid={supplierid}
      />
    </>  
  );
}

export default SupplierInfoEditableField;