import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ReactPlayer from 'react-player';

// styles the box container for the video
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  px: 4,
  pb: 8,
};

// default Component with open, onClose, title and url parameters
const ReactPlayerModal = ({ open, onClose, title, url }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <h1 style={{ textAlign: 'center', fontWeight: '400' }}>{title}</h1>
          <ReactPlayer url={url} controls={true} />
        </Box>
      </Modal>
    </div>
  );
};

export default ReactPlayerModal;


/* // To use:
    
    1.import ReactPLayerModal =>
  import ReactPlayerModal from '../Components/ReactPlayerModal/ReactPlayerModal';

    2. // declare the below const's to open and close the modal
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

    3. Create a button, with an onClick set to handleModalOpen const from above, to open modal
  <Button onClick={handleModalOpen}>Open modal</Button>

    4. Intantiate the ReactPlayerModal with open, onclose, title and url attributes
  <ReactPlayerModal 
      open={modalOpen} 
      onClose={handleModalClose} 
      title='First Video'
      url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
*/

