import { supabase } from '../../../../Utilities/SupabaseClient';
import getSupplierIDFromDB from '../../Utilities/getSupplierIDFromDB';

const updateSuppliersDB = async (values, assessmentid) => {
  const supplierUUID = await getSupplierIDFromDB(assessmentid);

  const { data, error } = await supabase
    .from('suppliers')
    .update({
      scope_2_emissions: values.annualScopeEmissions,
      revenue: values.approximateAnnualRevenue,
    })
    .eq('uuid', supplierUUID)
    .select();
};

export default updateSuppliersDB;
