import { useNavigate } from 'react-router-dom';
import { Grid, Box, Button } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';

import AssessmentHeader from '../../../Components/Headers/AssessmentHeader';
import OneLineInput from '../../../Components/OneLineInput/OneLineInput';
import SelectInput from '../../../Components/SelectInput/SelectInput';
import TextAreaInput from '../../../Components/TextAreaInput/TextAreaInput';

import { insertIntoDB } from './Utilities/insertIntoDB';
import setAssessmentResponseField from './Utilities/setAssessmentResponseField';
import styles from '../SupplierInitialAssessment.module.css';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';
import GoBackLink from '../Subcomponents/GoBackLink';

// Begin assessment from component
const SupplierInitialAssessment = ({ assessmentid, disableBtn }) => {
  // Gets assessment title
  const title = getAssessmentTitle(10001);
  const navigate = useNavigate();

  // Initial form values set to empty strings
  const initialValues = {
    companyName: '',
    climateChangePolicy: '',
    climateChangePolicyDescription: '',
    climateTeamResponsible: '',
    climateTeamDescription: '',
    currentlyMeasureEmissions: '',
    currentlyMeasuredEmissionsProgramDescription: '',
    scopesMeasuredDescription: '',
    emissionsDataStoredDescription: '',
  };

  // Validates inputs for required fields
  const validateSchema = yup.object().shape({
    companyName: yup.string().required('Required'),
    climateChangePolicy: yup
      .string()
      .ensure()
      .required('Please select an answer'),
    climateChangePolicyDescription: yup
      .string()
      .ensure()
      .when('climateChangePolicy', {
        is: 'Yes',
        then: yup.string().required('Please enter in a description.'),
      }),
    climateTeamResponsible: yup
      .string()
      .ensure()
      .required('Please select an answer')
      .oneOf(['Yes', 'No']),
    climateTeamDescription: yup
      .string()
      .ensure()
      .when('climateTeamResponsible', {
        is: 'Yes',
        then: yup.string().required('Please enter in a description.'),
      }),
    currentlyMeasureEmissions: yup
      .string()
      .ensure()
      .required('Please select an answer')
      .oneOf(['Yes', 'No']),
    currentlyMeasuredEmissionsProgramDescription: yup
      .string()
      .ensure()
      .when('currentlyMeasureEmissions', {
        is: 'Yes',
        then: yup.string().required('Please enter in a description.'),
      }),
    scopesMeasuredDescription: yup
      .string()
      .ensure()
      .when('currentlyMeasureEmissions', {
        is: 'Yes',
        then: yup.string().required('Please enter in a description.'),
      }),
    emissionsDataStoredDescription: yup
      .string()
      .ensure()
      .when('currentlyMeasureEmissions', {
        is: 'Yes',
        then: yup.string().required('Please enter in a description.'),
      }),
  });

  // Inserts data into the database when submit button is clicked
  const handleFormSubmit = (values, { resetForm }) => {
    try {
      if (!disableBtn) {
        console.log('DB write hit');
        insertIntoDB(values, assessmentid);
        setAssessmentResponseField(assessmentid);
      }
      resetForm(initialValues);
      navigate('/confirmation');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <main className={styles.initialAssessmentMain}>
        <Box mt='20px'>
          <GoBackLink />
          <AssessmentHeader title={title} videoLink={'https://youtu.be/fFMSWf3ARGQ'} />
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <div>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validateSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      display='grid'
                      gap='30px'
                      gridTemplateColumns='repeat(1, minmax(0, 1fr))'
                    >
                      <div>
                        <OneLineInput
                          inputLabel='Company Name'
                          type='text'
                          label='Company Name'
                          name='companyName'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={!!touched.companyName && !!errors.companyName}
                          helperText={touched.companyName && errors.companyName}
                        />
                      </div>

                      <div>
                        <SelectInput
                          className={styles.selectInput}
                          inputLabel='Does your company have a climate change policy or
                        program?'
                          name='climateChangePolicy'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.climateChangePolicy}
                          error={
                            !!touched.climateChangePolicy &&
                            !!errors.climateChangePolicy
                          }
                          helperText={
                            touched.climateChangePolicy &&
                            errors.climateChangePolicy
                          }
                          menuItemValue='Yes'
                          menuItemValue2='No'
                        />
                        {values.climateChangePolicy === 'Yes' ? (
                          <TextAreaInput
                            inputLabel='Describe your climate change policy or provide a
                        link to it.'
                            name='climateChangePolicyDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={
                              !!touched.climateChangePolicyDescription &&
                              !!errors.climateChangePolicyDescription
                            }
                            helperText={
                              touched.climateChangePolicyDescription &&
                              errors.climateChangePolicyDescription
                            }
                          />
                        ) : null}
                      </div>

                      <div>
                        <SelectInput
                          inputLabel='Does the company have a person or team responsible for
                        measuring and/or managing emissions?'
                          name='climateTeamResponsible'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.climateTeamResponsible}
                          error={
                            !!touched.climateTeamResponsible &&
                            !!errors.climateTeamResponsible
                          }
                          helperText={
                            touched.climateTeamResponsible &&
                            errors.climateTeamResponsible
                          }
                          menuItemValue='Yes'
                          menuItemValue2='No'
                        />
                        {values.climateTeamResponsible === 'Yes' ? (
                          <TextAreaInput
                            inputLabel='Describe your climate team.'
                            name='climateTeamDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={
                              !!touched.climateTeamDescription &&
                              !!errors.climateTeamDescription
                            }
                            helperText={
                              touched.climateTeamDescription &&
                              errors.climateTeamDescription
                            }
                          />
                        ) : null}
                      </div>

                      <div>
                        <SelectInput
                          inputLabel='Does the company currently measure its emissions?'
                          name='currentlyMeasureEmissions'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.currentlyMeasureEmissions}
                          error={
                            !!touched.currentlyMeasureEmissions &&
                            !!errors.currentlyMeasureEmissions
                          }
                          helperText={
                            touched.currentlyMeasureEmissions &&
                            errors.currentlyMeasureEmissions
                          }
                          menuItemValue='Yes'
                          menuItemValue2='No'
                        />
                        {values.currentlyMeasureEmissions === 'Yes' ? (
                          <>
                            <TextAreaInput
                              inputLabel='Describe your current emissions measurement
                          program.'
                              name='currentlyMeasuredEmissionsProgramDescription'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={
                                !!touched.currentlyMeasuredEmissionsProgramDescription &&
                                !!errors.currentlyMeasuredEmissionsProgramDescription
                              }
                              helperText={
                                touched.currentlyMeasuredEmissionsProgramDescription &&
                                errors.currentlyMeasuredEmissionsProgramDescription
                              }
                            />

                            <TextAreaInput
                              inputLabel='Which scopes are currently measured? How are the
                          measurements done?'
                              name='scopesMeasuredDescription'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={
                                !!touched.scopesMeasuredDescription &&
                                !!errors.scopesMeasuredDescription
                              }
                              helperText={
                                touched.scopesMeasuredDescription &&
                                errors.scopesMeasuredDescription
                              }
                            />

                            <TextAreaInput
                              inputLabel='How is your emissions data stored?'
                              name='emissionsDataStoredDescription'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={
                                !!touched.emissionsDataStoredDescription &&
                                !!errors.emissionsDataStoredDescription
                              }
                              helperText={
                                touched.emissionsDataStoredDescription &&
                                errors.emissionsDataStoredDescription
                              }
                            />
                          </>
                        ) : null}
                      </div>
                    </Box>
                    <Box display='flex' sx={{ m: '25px 0px 50px 0px' }}>
                      <Button
                        type='submit'
                        color='secondary'
                        variant='contained'
                      >
                        Submit Form
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </div>
          </Grid>
        </Box>
      </main>
    </>
  );
};

export default SupplierInitialAssessment;
