import { useEffect, useState } from 'react';
import AssessmentResponseHeader from '../../../Components/Headers/AssessmentResponseHeader';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';
import getAssessmentResponse from '../Utilities/getAssessmentResponse';
import getBlockResponses from './Utilities/getBlockResponses';
import ResponseItem from '../Subcomponents/AssessmentResponseItem';
import ResponseDate from '../Subcomponents/ReponseDate';
import styles from '../AssessmentResponse.module.css';

// Shows the responses that a supplier gave to Assessment 10006.
const Assessment10006Response = ( {assessmentid} ) => {
  const title = getAssessmentTitle(10006);
  const [assessmentResponse, setAssessmentResponse] = useState(null);

  useEffect(() => {
    async function getResponseInfo() {
      const incomingResponse = await getAssessmentResponse('supplier_assessment_10006', assessmentid);
      setAssessmentResponse(incomingResponse);
    }
    getResponseInfo()

  }, [assessmentid]);

  const responseBlocks = getBlockResponses(assessmentResponse);

  // Blank component until assessmentResponse has a value from
  // the DB call.
  if (!assessmentResponse) return null;

  return(
    <main className={styles.assessmentresponsemain}>
      <div className={styles.assessmentresponsecontentwrapper}>
        <AssessmentResponseHeader title={title} />
        <ResponseItem 
          question='Company Name'
          answer={assessmentResponse['company_name']}         
        />
        <ResponseItem 
          question='In the fields below, list the locations of your company’s largest 5 offices or 
          facilities and the purchased electricity in kilowatt hours (kWh) each uses
          annually. If you have fewer than 5 locations, leave the remaining boxes
          blank. This information can usually be found on the utility bills you receive. Do not include
          renewable energy.'
          answer={responseBlocks[0]}         
        />
        <ResponseItem 
          question='How much purchased electricity does your company use annually that is not included above? If the answer is none, enter 0 below.'
          answer={assessmentResponse['other_electricity'] ? assessmentResponse['other_electricity'] + ' kWh' : null} // Ternary to deal with null value possibility 
        />
        <ResponseItem 
          question='Which year does this represent?'
          answer={assessmentResponse['representational_year']}         
        />
        <ResponseItem 
          question='Calendar or fiscal year?'
          answer={assessmentResponse['calendar_or_fiscal']}         
        />
        <ResponseItem 
          question='We need information about your purchases of renewable energy and/or renewable 
          energy credits. This is used to determine if your location-based Scope 2 emissions 
          are different than your market-based Scope 2 emissions. List the kWh of renewable 
          energy you purchase (if any) below and the locations the energy is used.'
          answer={responseBlocks[1]}         
        />
        <ResponseItem 
          question='How much renewable energy does your company use annually that is not included above? If the answer is none, enter 0 below.'
          answer={assessmentResponse['other_renewable'] ? assessmentResponse['other_renewable'] + ' kWh' : null} // Ternary to deal with null value possibility         
        />
        <ResponseItem 
          question='Does your company purchase steam, heat, or cooling (not common)? If so, our support team will contact you to learn more about this.'
          answer={assessmentResponse['other_scope_2_energy_purchases']}         
        />
        <ResponseItem 
          question='Is there anything else your company does that contributes to Scope 2 emissions? If so, our support team will contact you.'
          answer={assessmentResponse['other_scope_2']}         
        />
        <ResponseItem
          question='What percentage of your company&#39;s Scope 2 emissions should be allocated to this customer? The most common method is to determine how much of your total revenue comes from the customer. For example, if you have $100,000 in revenue and $4,500 comes from the customer, then enter 4.5 here. Another way is to determine how much of your total emissions are related to the customer, based on business activities. Enter the percentage as a number without a percent sign (i.e. for 15% enter 15).' 
          answer={assessmentResponse['allocation_percentage']}
        />
        <ResponseDate 
          assessmentResponse={assessmentResponse} 
        />

      </div>
    </main>
  );
}

export default Assessment10006Response;