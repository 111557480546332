import { supabase } from '../../../Utilities/SupabaseClient';

// Returns an array for the bar chart. The array contains
// objects of emissions for the suppliers.
const getSupplierEmissions = async () => {
  try {
    const session = await supabase.auth.getSession();
    const userid = session.data.session.user.id;
    const {data, err} = await supabase.from('suppliers').select('company_name, scope_1_emissions, scope_2_emissions, allocation_pct_scope_1, allocation_pct_scope_2').eq('user_added_supplier_id', userid);
    // Error block here in case the catch block does not
    // recognize the error. Not sure how Supabase handles the error
    // message so including this here. May not be necessary.
    if (err) throw (err);
    if (data) {
      const supplierData = []
      data.forEach((item) => {
        if (!(item['company_name'] && item['scope_1_emissions'] && item['allocation_pct_scope_1'] && item['allocation_pct_scope_2']) ) return;
        const dataObj = {
          company: item['company_name'],
          'Scope 1' : ( item['scope_1_emissions'] * (item['allocation_pct_scope_1'] / 100) ).toFixed(1),
          'Scope 2' : ( item['scope_2_emissions'] * (item['allocation_pct_scope_2'] / 100) ).toFixed(1),
          'Total' : ( (item['scope_1_emissions'] * (item['allocation_pct_scope_1'] / 100)) + (item['scope_2_emissions'] * (item['allocation_pct_scope_2'] / 100)) )
        }
        supplierData.push(dataObj);
      })
      const sortedSupplierData = supplierData.sort((a, b) => {
        return (b['Total'] - a['Total']);
      });
      console.log(sortedSupplierData);
      return (sortedSupplierData.slice(0,6));
    }
  } catch(e) {
    console.log(e);
    throw(e);
  }
}

export default getSupplierEmissions;