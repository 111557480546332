import { useEffect, useState } from 'react';
import AssessmentResponseHeader from '../../../Components/Headers/AssessmentResponseHeader';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';
import getAssessmentResponse from '../Utilities/getAssessmentResponse';
import ResponseItem from '../Subcomponents/AssessmentResponseItem';
import ResponseDate from '../Subcomponents/ReponseDate';
import styles from '../AssessmentResponse.module.css';

// Shows the responses that a supplier gave to Assessment10005.
const Assessment10005Response = ( {assessmentid} ) => {
  const title = getAssessmentTitle(10005);
  const [assessmentResponse, setAssessmentResponse] = useState(null);

  useEffect(() => {
    async function getResponseInfo() {
      const incomingResponse = await getAssessmentResponse('supplier_assessment_10005', assessmentid);
      setAssessmentResponse(incomingResponse);
    }
    getResponseInfo()

  }, [assessmentid]);

  // Blank component until assessmentResponse has a value from
  // the DB call.
  if (!assessmentResponse) return null;

  return(
    <main className={styles.assessmentresponsemain}>
      <div className={styles.assessmentresponsecontentwrapper}>
        <AssessmentResponseHeader title={title} />
        <ResponseItem 
          question='Company Name'
          answer={assessmentResponse['company_name']}         
        />
        <ResponseItem 
          question='How much gasoline (gallons) does your company use annually in cars and light trucks that it owns or has operational control over? If none, enter 0.'
          answer={assessmentResponse['gasoline_cars']}         
        />
        <ResponseItem 
          question='How much gasoline (gallons) does your company use annually in heavy duty trucks it owns or has operational control over?'
          answer={assessmentResponse['gasoline_trucks']}         
        />
        <ResponseItem 
          question='How much diesel (gallons) does your company use annually in heavy duty trucks it owns or has operational control over?'
          answer={assessmentResponse['diesel_trucks']}         
        />
        <ResponseItem 
          question='How much diesel (gallons) does your company use for other purposes (non-vehicle uses)?'
          answer={assessmentResponse['diesel_other']}         
        />
        <ResponseItem 
          question='How much biodiesel (gallons) does your company use? (Do not include these biodiesel gallons in the diesel numbers for the previous 2 questions.)'
          answer={assessmentResponse['biodiesel']}         
        />
        <ResponseItem 
          question='How much (short tons) Anthracite coal does your company burn annually?'
          answer={assessmentResponse['anthracite']}         
        />
        <ResponseItem 
          question='How much brown coal (short tons) does your company burn annually?'
          answer={assessmentResponse['brown_coal']}         
        />
        <ResponseItem 
          question='How much coking coal (short tons) does your company burn annually?'
          answer={assessmentResponse['coking_coal']}         
        />
        <ResponseItem 
          question='How much crude oil (barrels) does your company burn annually?'
          answer={assessmentResponse['crude_oil']}         
        />
        <ResponseItem 
          question='How much natural gas (MMBtu) does your company burn annually?'
          answer={assessmentResponse['natural_gas']}         
        />
        <ResponseItem 
          question='How much kerosene-type jet fuel (gallons) does your company use annually?'
          answer={assessmentResponse['kerosene_jet_fuel']}         
        />
        <ResponseItem 
          question='How much jet gasoline (gallons) does your company use annually?'
          answer={assessmentResponse['jet_gasoline']}         
        />
        <ResponseItem 
          question='How much ethane (gallons) does your company use annually?'
          answer={assessmentResponse['ethane']}         
        />
        <ResponseItem 
          question='How much propane (gallons) does your company burn annually?'
          answer={assessmentResponse['propane']}         
        />
        <ResponseItem 
          question='How much biogas (cubic meters) does your company burn annually?'
          answer={assessmentResponse['biogas']}         
        />
        <ResponseItem 
          question='Does your company generate other Scope 1 emissions, or is it involved in combustion other than what is described above? If yes, our support team will contact you.'
          answer={assessmentResponse['other_combustion']}         
        />
        <ResponseItem 
          question='Is your company involved in any of the following: chemical production, oil and gas, steel production, aluminum production, waste and landfills, pulp and paper, cement production, mining, or other metal production? If so, our support team will contact you to discuss industry specific Scope 1 measurement.'
          answer={assessmentResponse['specific_industry']}         
        />
        <ResponseItem 
          question='Which year does the above data represent?'
          answer={assessmentResponse['representational_year']}         
        />
        <ResponseItem 
          question='Calendar or fiscal year?'
          answer={assessmentResponse['calendar_or_fiscal']}         
        />
        <ResponseItem
          question='What percentage of your company&#39;s Scope 1 emissions should be allocated to this customer? The most common method is to determine how much of your total revenue comes from the customer. For example, if you have $100,000 in revenue and $4,500 comes from the customer, then enter 4.5 here. Another way is to determine how much of your total emissions are related to the customer, based on business activities. Enter the percentage as a number without a percent sign (i.e. for 15% enter 15).' 
          answer={assessmentResponse['allocation_percentage']}
        />
        <ResponseDate 
          assessmentResponse={assessmentResponse} 
        />
      </div>
    </main>
  );
}

export default Assessment10005Response;