import { useEffect, useState } from 'react';
import AssessmentResponseHeader from '../../../Components/Headers/AssessmentResponseHeader';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';
import getAssessmentResponse from '../Utilities/getAssessmentResponse';
import ResponseItem from '../Subcomponents/AssessmentResponseItem'; 
import ResponseDate from '../Subcomponents/ReponseDate';
import styles from '../AssessmentResponse.module.css';

// Shows the responses that a supplier gave to Assessment10003.
const Assessment10003Response = ( {assessmentid} ) => {
  const title = getAssessmentTitle(10003);
  const [assessmentResponse, setAssessmentResponse] = useState(null);

  useEffect(() => {
    async function getResponseInfo() {
      const incomingResponse = await getAssessmentResponse('supplier_scope1_assessment', assessmentid);
      setAssessmentResponse(incomingResponse);
    }
    getResponseInfo()

  }, [assessmentid]);

  // Blank component until assessmentResponse has a value from
  // the DB call.
  if (!assessmentResponse) return null;

  return(
    <main className={styles.assessmentresponsemain}>
      <div className={styles.assessmentresponsecontentwrapper}>
        <AssessmentResponseHeader title={title} />
        <ResponseItem 
          question='Company Name'
          answer={assessmentResponse['company_name']}         
        />
        <ResponseItem 
          question='Does your company know it’s Scope 1 emissions?'
          answer={assessmentResponse['scope_1_emissions']}         
        />
        <ResponseItem 
          question='What are your annual Scope 1 emissions (metric tons of CO2 equivalent)?'
          answer={assessmentResponse['annual_scope_emissions']}         
        />
        <ResponseItem 
          question='Which year does this represent?'
          answer={assessmentResponse['representational_year']}         
        />
        <ResponseItem 
          question='Calendar or fiscal year?'
          answer={assessmentResponse['calendar_or_fiscal_year']}         
        />
        <ResponseItem 
          question='What percentage of your company&#39;s Scope 2 emissions should be allocated to this customer?'
          answer={assessmentResponse['allocation_percentage']}         
        />
        <ResponseDate 
          assessmentResponse={assessmentResponse} 
        />
      </div>
    </main>
  );
}

export default Assessment10003Response;