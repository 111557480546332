import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

// Returns the columns needed to populate the MUI Grid.
const getColumns = (colors) => {
	const columns = [
		{ field: 'company_name', headerName: 'Company Name', flex: 1 },
		{
			field: 'assessment_name',
			headerName: 'Assessment',
			flex: 1.25,
		},
		{
			field: 'contact_name',
			headerName: 'Contact',
			flex: 0.7,
			cellClassName: 'contact-name-column--cell',
		},
		{
			field: 'email',
			headerName: 'Email',
			flex: 1,
		},
    {
      field: 'date',
			headerName: 'Date Completed',
			flex: 1,
    },
		{
			field: 'response_page',
			headerName: 'Response Detail',
			flex: 1,
			// Renders a button within the MUI Grid.
			renderCell: ({ row: { response_page } }) => {
				return (
					<Box width='100%' display='flex' justifyContent='center'>
						<Button
							component={Link}
							to={response_page}
							sx={{
								backgroundColor: colors.greenAccent[600],
								'&:hover': {
									backgroundColor: colors.greenAccent[700],
								},
								textTransform: 'none',
								minHeight: '0',
								minWidth: '0',
								padding: '4px',
								paddingLeft: '12px',
								paddingRight: '12px',
								fontSize: '12px',
							}}
						>
							View Response
						</Button>
					</Box>
				);
			},
		},
	];
	return columns;
};

export default getColumns;
