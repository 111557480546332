import { validate as uuidValidate } from 'uuid';
import { supabase } from '../../../Utilities/SupabaseClient';

// This function takes in an assessment uuid and returns
// the assessment number (effectively the assessment type)
// corresponding to that uuid.
const getAssessmentNumberFromDB = async (assessmentid) => {
  // Check to see if uuid is valid
  const isValid = uuidValidate(assessmentid);
  if (!isValid) return [];

  // Error block here in case the catch block does not
  // recognize the error. Not sure how Supabase handles the error
  // message so including this here. May not be necessary.
  try {
    const {data, err} = await supabase.from('assessments_sent').select('*').eq('assessment_uuid', assessmentid);
    if (err) throw (err);
    if (data) {
      return (data);
    }
  } catch(e) {
    console.log(e);
    throw(e);
  }
}

export default getAssessmentNumberFromDB;