import { useState } from 'react';
import { Box } from '@mui/material';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import ReactPlayerModal from '../ReactPlayerModal/ReactPlayerModal';
import styles from './AssessmentHeader.module.css';

const Header = ({ title, subtitle, videoLink }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  return (
    <Box mb="30px">
      {/* <Typography
        // variant="h4"
        // fontWeight="bold"
        sx={{ fontSize: '4vh' }}
      > */}
      <div className={styles.assessmentheadertitleblock} >
        <div className={styles.assessmentheadertitle}>{title}</div>
        {videoLink && <div className={styles.assessmentheadericon}>
          <button className={styles.assessmentheadericonbutton} onClick={handleModalOpen}>
            <PlayCircleOutlinedIcon sx={{ fontSize: '4vh' }} />
          </button>
        </div>}        
      </div>
      {/* </Typography> */}
      <ReactPlayerModal 
        open={modalOpen} 
        onClose={handleModalClose}
        title={title}
        url={videoLink} 
      />
    </Box>
  );
};

export default Header;