import { supabase } from '../../../Utilities/SupabaseClient';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';
import getSupplierInfoFromDB from '../../SupplierPage/Utilities/getSupplierInfoFromDB';
import getResponseCompletionDateFromDB from './getResponseCompletionDateFromDB';

// This function gets the received assessment responses from the DB.
const getResponseListFromDB = async (userid) => {
	try {
		const { data, error } = await supabase
			.from('assessments_sent')
			.select('*')
			.eq('response', true)
			.eq('sending_user_id', userid);

		if (error) throw error;

		const cleanedData = await cleanData(data);
		return cleanedData;
	} catch (e) {
		console.log(e);
		throw e;
	}
};

// This function takes the data from the DB and returns the data needed by
// the MUI grid table used to display the suppliers.
const cleanData = async (data) => {
	const supplierUUIDs = data.map((item) => item['supplier_uuid']);
	const assessmentNumbers = data.map((item) => item['assessment_number']);
	const assessmentUUIDs = data.map((item) => item['assessment_uuid']);

	// Fetch supplier info, assessment titles, and completion dates in parallel
	const [supplierInfos, assessmentNames, completionDates] = await Promise.all([
		Promise.all(supplierUUIDs.map((uuid) => getSupplierInfoFromDB(uuid))),
		Promise.all(assessmentNumbers.map((num) => getAssessmentTitle(num))),
		Promise.all(
			data.map((item) =>
				getResponseCompletionDateFromDB(
					item['assessment_number'],
					item['assessment_uuid']
				)
			)
		),
	]);

	const cleanData = data
		.map((item, index) => {
			const dateCompleted = completionDates[index];
			if (dateCompleted === null) return null;

			const date = new Date(dateCompleted);
			const options = { year: 'numeric', month: 'short', day: 'numeric' };
			const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
				date
			);

			return {
				id: index + 1,
				company_name: supplierInfos[index]['company_name'],
				assessment_name: assessmentNames[index],
				email: supplierInfos[index]['email'],
				date: formattedDate,
				contact_name: `${supplierInfos[index]['contact_first_name']} ${supplierInfos[index]['contact_last_name']}`,
				response_page: `/response/${assessmentUUIDs[index]}`,
			};
		})
		.filter((item) => item !== null); // Filter out any null entries

	return cleanData;
};

export default getResponseListFromDB;
