import { supabase } from '../../../Utilities/SupabaseClient';

// This function gets the suppliers from the DB. It should only get
// suppliers associated with the logged in user, given the RLS setup.
const getSuppliersListFromDB = async () => {
  try {
    const {data, err} = await supabase.from('suppliers').select('*');
    // Error block here in case the catch block does not
    // recognize the error. Not sure how Supabase handles the error
    // message so including this here. May not be necessary.
    if (err) throw (err);
    const cleanedData = cleanData(data);
    return cleanedData;
  } catch(e) {
    console.log(e);
    throw(e);
  }
}

// This function takes the data from the DB and returns the data needed by
// React Search Autocomplete.
const cleanData = (data) => {
  const cleanData = [];
  for (let k = 0; k < data.length; k++) {
    const itemToAdd = {
      id: k,
      name: data[k]['company_name'],
      supplieruuid: data[k]['uuid'],
    }
    cleanData.push(itemToAdd);

  }
  return cleanData;
}

export default getSuppliersListFromDB;