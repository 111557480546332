import { supabase } from '../../../Utilities/SupabaseClient';
import getSupplierInfoFromDB from '../../SupplierPage/Utilities/getSupplierInfoFromDB';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';

// Returns an array for the bar chart. The array contains
// objects of emissions for the suppliers.
const getRecentResponses = async () => {
	try {
		const session = await supabase.auth.getSession();
		const userid = session.data.session.user.id;
		const { data, err } = await supabase
			.from('assessments_sent')
			.select('*')
			.eq('response', true)
			.eq('sending_user_id', userid)
			.order('created_at', { ascending: false })
			.limit(15);
		// Error block here in case the catch block does not
		// recognize the error. Not sure how Supabase handles the error
		// message so including this here. May not be necessary.
		if (err) throw err;
		if (data) {
			const supplierData = [];
			for (let j = 0; j < data.length; j++) {
				const date = new Date(data[j]['created_at']).toDateString();
				const supplierInfo = await getSupplierInfoFromDB(
					data[j]['supplier_uuid']
				);
				const dataObj = {
					company: supplierInfo['company_name'],
					assessment: getAssessmentTitle(data[j]['assessment_number']),
					uuid: data[j]['assessment_uuid'],
					date,
				};
				supplierData.push(dataObj);
			}
			return supplierData.slice(0, 9);
		}
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export default getRecentResponses;
