import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import useWindowDimensions from '../../Utilities/Hooks/UseWindowDimensions';
import SideBarPanel from '../../NavBars/SideBarPanel';
import TopBar from '../../NavBars/TopBar';
import styles from './SendAssessment.module.css';
import { useEffect } from 'react';
import getSuppliersListFromDB from './Utilites/getSuppliersListFromDB';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';

// This component displays the page which allows the user to search for a
// supplier.
const SendAssessment = () => {
  const [searchboxText, setSearchboxText] = useState('');
  const [suppliersList, setSuppliersList] = useState([]);
  const [searchDoesNotMatch, setSearchDoesNotMatch] = useState(false);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const inputFieldHeight = width > 600 ? '48px' : '32px';
  const inputFontSize = width > 600 ? '16px' : '14px';

  useEffect(() => {
    async function getData() {
      const incomingSuppliersData = await getSuppliersListFromDB();
      console.log(incomingSuppliersData);
      setSuppliersList(incomingSuppliersData);
    }
    getData();
  }, []);

  // Controls the formatting for results from the autocomplete box.
  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    );
  };

  // Navigates to the supplier page on select.
  const handleOnSelect = (item) => {
    const uuid = item.supplieruuid;
    navigate(`/supplier/${uuid}`);
  };

  // Stores the text in the search box
  const handleOnSearch = (string, results) => {
    setSearchboxText(string);
    setSearchDoesNotMatch(false);
  };

  // Deals with the button click in which case the current entry in
  // the search box needs to be pulled from state. If there is a match,
  // the user is sent to the matching supplier page. Otherwise
  // an error message shown.
  const handleButtonClick = (event) => {
    event.preventDefault();
    let uuid = '';
    for (let m = 0; m < suppliersList.length; m++) {
      if (suppliersList[m]['name'] === searchboxText) {
        uuid = suppliersList[m]['supplieruuid'];
      }
    }
    if (uuid) {
      navigate(`/supplier/${uuid}`);
    } else {
      setSearchDoesNotMatch(true);
    }
  };

  return (
    <>
      <SideBarPanel />
      <main className={styles.sendassessmentmain}>
        <nav>
          <TopBar />
        </nav>

        <div className={styles.sendassessmentwrapper}>
          <Typography variant='h3' color={colors.grey[100]} sx={{ mb: '60px' }}>
            Search for a supplier to go its assessments page
          </Typography>
          {/* <h2 className={styles.sendassessmenth2}>
            Search for a supplier to go its assessments page
          </h2> */}
          <div className={styles.sendassessmentinput}>
            <ReactSearchAutocomplete
              items={suppliersList}
              onSearch={handleOnSearch}
              // onHover={handleOnHover}
              onSelect={handleOnSelect}
              // onFocus={handleOnFocus}
              autoFocus
              formatResult={formatResult}
              styling={{
                height: inputFieldHeight,
                fontSize: inputFontSize,
                border: '1px solid #202121',
                fontFamily: '"Source Sans Pro", sans-serif',
                borderRadius: '4px',
                backgroundColor: '#f7f8fa',
              }}
              // fuseOptions={{ keys: ['name', 'ticker'] }}
            ></ReactSearchAutocomplete>
          </div>
          {searchDoesNotMatch && (
            <div className={styles.sendassessmentnomatch}>
              There is no supplier with that name.
            </div>
          )}
          <div className={styles.sendassessmentbuttonwrapper}>
            <button
              className={styles.sendassessmentbutton}
              onClick={(event) => handleButtonClick(event)}
            >
              Search
            </button>
          </div>
        </div>
      </main>
    </>
  );
};

export default SendAssessment;
