import { supabase } from '../../../Utilities/SupabaseClient';

// Returns the records from the assessments_sent table
// in the DB for which the supplierid matches the 
// DB supplier_uuid.
const getAssessmentsInfoFromDB = async (supplierid) => {
  try {  
    const {data, err} = await supabase.from('assessments_sent').select('*').eq('supplier_uuid', supplierid);
    // Error block here in case the catch block does not
    // recognize the error. Not sure how Supabase handles the error
    // message so including this here. May not be necessary.
    if (err) throw (err);
    if (data) {
      const assessmentsSent = []; // Sent but no response
      const assessmentsWithResponse = [];  // Sent and responded to
      const assessmentsUUID = []
      data.forEach((item) => {
        assessmentsUUID.push(item['assessment_uuid'])
        if (item['response'] === true) assessmentsWithResponse.push(parseInt(item['assessment_number']));
        else (assessmentsSent.push(parseInt(item['assessment_number'])));
      })
      return ([assessmentsSent, assessmentsWithResponse, assessmentsUUID]);
    }
  } catch(e) {
    console.log(e);
    throw(e);
  }
}

export default getAssessmentsInfoFromDB;