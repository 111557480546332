import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Box, Typography, useTheme, IconButton } from '@mui/material';

import Item from '../Components/Item';
import MenuOutlined from '@mui/icons-material/MenuOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAddOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { tokens } from '../theme';

import LogOutButton from '../Components/LogoutButton/LogOutButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../contexts/AuthContext';

import { supabase } from '../Utilities/SupabaseClient';

import OneLineInput from '../Components/OneLineInput/OneLineInput';
import Button from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import 'react-pro-sidebar/dist/css/styles.css';

// This component renders the sidebar at the left side of the Dashboard.
const SidebarPanel = () => {
  const theme = useTheme();
  const auth = useAuth();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const initialState = checkInitialState(location);
  const [selected, setSelected] = useState(initialState);

  const handleSignOut = async () => {
    await auth.signOut();
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    getProfile();
  };

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const getProfile = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();

    try {
      let { data, error, status } = await supabase
        .from('user_details')
        .select(`first_name, last_name, role, profile_image, uuid`)
        .eq('uuid', user.id);
      if (!data.length) {
        const { error } = await supabase
          .from('user_details')
          .insert({ uuid: user.id });
      } else {
        setFirstName(data[0].first_name);
        setLastName(data[0].last_name);
        setRole(data[0].role);
        setImageUrl(data[0].profile_image);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const updateProfile = async (e) => {
    e.preventDefault();

    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      const updates = {
        first_name: firstName,
        last_name: lastName,
        role: role,
        profile_image: imageUrl,
      };

      let { error } = await supabase
        .from('user_details')
        .update(updates)
        .eq('uuid', user.id);

      if (error) {
        throw error;
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  let imageToShow = 'https://cdn.pixabay.com/photo/2014/04/02/10/25/man-303792_1280.png'
  if (imageUrl) {
    imageToShow = imageUrl;
  }

  return (
    <>
      <nav>
        <Box
          // Styles the React Pro Sidebar
          sx={{
            height: '100%',
            background: `${colors.primary[400]}`,
            '& .pro-sidebar-inner': {
              background: `${colors.primary[400]} !important`,
            },
            '& .pro-sidebar': {
              height: '100vh !important',
            },
            '& .pro-icon-wrapper': {
              backgroundColor: 'transparent !important',
            },
            '& .pro-inner-item': {
              padding: '5px 20px 5px 20px !important',
            },
            '& .pro-inner-item:hover': {
              color: '#868dfb !important',
            },
            '& .pro-menu-item.active': {
              color: '#6870fa !important',
            },
          }}
        >
          <ProSidebar collapsed={isCollapsed} width={240}>
            <Menu iconShape='square'>
              {/* LOGO AND MENU ICON */}
              <MenuItem
                onClick={() => setIsCollapsed(!isCollapsed)}
                icon={isCollapsed ? <MenuOutlined /> : undefined}
                style={{
                  margin: '10px 0 10px 0',
                  color: colors.grey[100],
                }}
              >
                {!isCollapsed && (
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    ml='15px'
                  >
                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                      <MenuOutlined />
                    </IconButton>
                  </Box>
                )}
              </MenuItem>
              {!isCollapsed && (
                <Box mb='15px'>
                  {/* User picture */}
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <img
                      alt='profile-user'
                      width='100px'
                      height='100px'
                      src={imageToShow}
                      style={{ cursor: 'pointer', borderRadius: '50%' }}
                      onClick={handleClickOpen}
                    />
                  </Box>
                  {/* User name and role */}
                  <Box textAlign='center'>
                    <Typography
                      variant='h3'
                      color={colors.grey[100]}
                      fontWeight='bold'
                      sx={{ m: '10px 18px 0 18px', overflowWrap: 'break-word' }}
                    >
                      {firstName} {lastName}
                    </Typography>
                    <Typography 
                      variant='h6'
                      color={colors.greenAccent[500]}
                      lineHeight={1.3}
                      sx={{ m: '6px 18px 0 15px', overflowWrap: 'break-word' }}
                    >
                      {role}
                    </Typography>
                  </Box>
                </Box>
              )}

              {/* MENU ITEMS */}
              <Box paddingLeft={isCollapsed ? undefined : '4%'}>
                <Item
                  title='Dashboard'
                  to='/dashboard'
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  colors={colors}
                />
                {!isCollapsed && (
                  <Typography
                    variant='h6'
                    color={colors.grey[300]}
                    sx={{ m: '10px 0 5px 20px' }}
                  >
                    Suppliers
                  </Typography>
                )}
                <Item
                  title='Suppliers List'
                  to='/supplierslist'
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  colors={colors}
                />
                <Item
                  title='Add Supplier'
                  to='/addsupplier'
                  icon={<PlaylistAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  colors={colors}
                />
                {!isCollapsed && (
                  <Typography
                    variant='h6'
                    color={colors.grey[300]}
                    sx={{ m: '10px 0 5px 20px' }}
                  >
                    Assessments
                  </Typography>
                )}
                <Item
                  title='Responses'
                  to='/assessmentresponses'
                  icon={<ForwardToInboxOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  colors={colors}
                />
                <Item
                  title='Send Assessment'
                  to='/sendassessment'
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  colors={colors}
                />
                {!isCollapsed && (
                  <Typography
                    variant='h6'
                    color={colors.grey[300]}
                    sx={{ m: '10px 0 5px 20px' }}
                  >
                    Data
                  </Typography>
                )}
                <Item
                  title='Emissions Detail'
                  to='/emissionsdetail'
                  icon={<BarChartOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  colors={colors}
                />
                {!isCollapsed && (
                  <Typography
                    variant='h6'
                    color={colors.grey[300]}
                    sx={{ m: '10px 0 5px 20px' }}
                  >
                    User
                  </Typography>
                )}
                <LogOutButton
                  title='Logout'
                  icon={<LogoutIcon />}
                  colors={colors}
                  onClick={handleSignOut}
                />
              </Box>
            </Menu>
          </ProSidebar>
        </Box>
      </nav>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ width: '500px', margin: 'auto' }}
      >
        <DialogTitle sx={{ fontSize: 20 }}>EDIT</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Click through to edit some or all of your personal profile
            information.
          </DialogContentText>
          <form>
            <OneLineInput
              autoFocus
              margin='dense'
              id='firstName'
              label='First Name'
              type='text'
              fullWidth
              variant='standard'
              value={firstName || ''}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <OneLineInput
              autoFocus
              margin='dense'
              id='lastName'
              label='Last Name'
              type='text'
              fullWidth
              variant='standard'
              value={lastName || ''}
              onChange={(e) => setLastName(e.target.value)}
            />
            <OneLineInput
              autoFocus
              margin='dense'
              id='role'
              label='Role'
              type='text'
              fullWidth
              variant='standard'
              value={role || ''}
              onChange={(e) => setRole(e.target.value)}
            />
            <OneLineInput
              autoFocus
              margin='dense'
              id='profileImageUrl'
              label='Image URL'
              type='text'
              fullWidth
              variant='standard'
              value={imageUrl || ''}
              onChange={(e) => setImageUrl(e.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateProfile}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SidebarPanel;

// This function location looks at the URL path and returns the
// name of the current page. This is used to determine which part
// item in the sidebar to make "selected/active.""
const checkInitialState = (location) => {
  switch (location.pathname) {
    case '/dashboard':
      return 'Dashboard';
    case '/addsupplier':
      return 'Add Supplier';
    case '/supplierslist':
      return 'Suppliers List';
    case '/sendassessment':
      return 'Send Assessment';
    case '/responses':
      return 'Responses';
    case '/assessmentresponses':
      return 'Responses';
    case '/emissionsdetail':
      return 'Emissions Detail';
    default:
      return 'Dashboard';
  }
};
