// Returns the columns needed to populate the MUI Datagrid.
const getColumns = () => {
	const columns = [
		{ field: 'company_name', headerName: 'Company Name', flex: 1 },
		// {
		//   field: "annual_spend",
		//   headerName: "Annual Spending ($)",
		//   type: 'number',
		//   flex: 1.2,
		// },
		{
			field: 'scope_1',
			headerName: 'Scope 1 Emissions',
			type: 'number',
			flex: 1,
			valueFormatter: emissionsNumberFormatter,
		},
		{
			field: 'scope_2',
			headerName: 'Scope 2 Emissions',
			type: 'number',
			flex: 1,
			valueFormatter: emissionsNumberFormatter,
		},
		{
			field: 'attribution_pct_scope_1',
			headerName: 'Attr Pct Scope 1',
			type: 'number',
			flex: 1,
			valueFormatter: (params) => {
				if (params.value == null) {
					return '';
				}

				const valueFormatted = Number(params.value).toFixed(1).toLocaleString();
				return `${valueFormatted} %`;
			},
		},
		{
			field: 'attribution_pct_scope_2',
			headerName: 'Attr Pct Scope 2',
			type: 'number',
			flex: 1,
			valueFormatter: (params) => {
				if (params.value == null) {
					return '';
				}

				const valueFormatted = Number(params.value).toFixed(1).toLocaleString();
				return `${valueFormatted} %`;
			},
		},
		{
			field: 'attributed_scope_1',
			headerName: 'Attributed Scope 1',
			type: 'number',
			flex: 1,
			valueFormatter: emissionsNumberFormatter,
		},
		{
			field: 'attributed_scope_2',
			headerName: 'Attributed Scope 2',
			type: 'number',
			flex: 1,
			valueFormatter: emissionsNumberFormatter,
		},
		{
			field: 'scope_1_and_2_attributed_emissions',
			headerName: 'Attributed Scope 1 & 2',
			type: 'number',
			flex: 1,
			valueFormatter: emissionsNumberFormatter,
		},
	];

	return columns;
};

const emissionsNumberFormatter = (params) => {
	if (params.value == null) {
		return '';
	}

	// const valueFormatted = Number(params.value).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	const valueFormatted = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 1,
		maximumFractionDigits: 1,
	});
	const value = valueFormatted.format(params.value);
	return value;
};

export default getColumns;
