import { useNavigate } from 'react-router-dom';
import { Grid, Box, Button } from '@mui/material';

import { Formik } from 'formik';
import * as yup from 'yup';

import AssessmentHeader from '../../../Components/Headers/AssessmentHeader';
import OneLineInput from '../../../Components/OneLineInput/OneLineInput';
import SelectInput from '../../../Components/SelectInput/SelectInput';
import LocationInput from '../../../Components/LocationInput/LocationInput';
import Width240Input from '../../../Components/Width240Input/Width240Input';

import { insertIntoDB } from './Utilities/insertIntoDB';
import setAssessmentResponseField from '../SupplierInitialAssessment/Utilities/setAssessmentResponseField';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';
import styles from '../SupplierInitialAssessment.module.css';
import GoBackLink from '../Subcomponents/GoBackLink';

// Begin assessment 10006 component.
const SupplierAssessment10006 = ({ assessmentid, disableBtn }) => {
  // Gets assessment title
  const title = getAssessmentTitle(10006);
  const navigate = useNavigate();

  // Initial form values set to empty strings
  const initialValues = {
    companyName: undefined,
    location1: undefined,
    location1kwh: undefined,
    location2: undefined,
    location2kwh: undefined,
    location3: undefined,
    location3kwh: undefined,
    location4: undefined,
    location4kwh: undefined,
    location5: undefined,
    location5kwh: undefined,
    otherelectricity: undefined,
    representationalYear: '2023',
    calendarOrFiscal: 'CY',
    renewablelocation1: undefined,
    renewablelocation1kwh: undefined,
    renewablelocation2: undefined,
    renewablelocation2kwh: undefined,
    renewablelocation3: undefined,
    renewablelocation3kwh: undefined,
    renewablelocation4: undefined,
    renewablelocation4kwh: undefined,
    renewablelocation5: undefined,
    renewablelocation5kwh: undefined,
    otherrenewable: undefined,
    otherscope2energypurchases: '',
    otherscope2: '',
    allocationpercentage: undefined,
  };

  // Validates inputs for required fields
  const validateSchema = yup.object().shape({
    companyName: yup.string().required('Required'),
    location1: yup.string().ensure().required('An answer is required'),
    location1kwh: yup
      .number()
      .min(0, 'Must be zero or positive')
      .required('An answer is required'),
    location2: yup.string().ensure(),
    location2kwh: yup.number().min(0, 'Must be zero or positive'),
    location3: yup.string().ensure(),
    location3kwh: yup.number().min(0, 'Must be zero or positive'),
    location4: yup.string().ensure(),
    location4kwh: yup.number().min(0, 'Must be zero or positive'),
    location5: yup.string().ensure(),
    location5kwh: yup.number().min(0, 'Must be zero or positive'),
    otherelectricity: yup.number().required('An answer is required').min(0, 'Must be zero or positive'),
    representationalYear: yup
      .string()
      .required('Please enter a Year.'),
    calendarOrFiscal: yup
      .string()
      .ensure()
      .required('Please select calendar or fiscal.'),
    renewablelocation1: yup.string().ensure(),
    renewablelocation1kwh: yup.number().min(0, 'Must be zero or positive'),
    renewablelocation2: yup.string().ensure(),
    renewablelocation2kwh: yup.number().min(0, 'Must be zero or positive'),
    renewablelocation3: yup.string().ensure(),
    renewableocation3kwh: yup.number().min(0, 'Must be zero or positive'),
    renewablelocation4: yup.string().ensure(),
    renewablelocation4kwh: yup.number().min(0, 'Must be zero or positive'),
    renewablelocation5: yup.string().ensure(),
    renewablelocation5kwh: yup.number().min(0, 'Must be zero or positive'),
    otherrenewable: yup.number().required('An answer is required').min(0, 'Must be zero or positive'),
    otherscope2energypurchases: yup
      .string()
      .ensure()
      .required('An answer is required'),
    otherscope2: yup.string().ensure().required('An answer is required'),
    allocationpercentage: yup
      .number()
      .min(0, 'Must be zero or positive')
      .max(100, 'Must be less than or equal to 100')
      .required('An answer is required'),
  });

  // Inserts data into the datbase when submit button is clicked
  const handleFormSubmit = (values, { resetForm }) => {
    try {
      // FIXME: add zipToSubregion call with parameters values.location (zipcode) and values.locationKWH 
      if (!disableBtn) {
        insertIntoDB(values, assessmentid);
        setAssessmentResponseField(assessmentid);
      }
      resetForm(initialValues);
      navigate('/confirmation');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <main className={styles.initialAssessmentMain}>
        <Box mt='20px' sx={{ maxWidth: 'md' }}>
          <GoBackLink />
          <AssessmentHeader title={title} />
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <div>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validateSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      display='grid'
                      gap='0px'
                      gridTemplateColumns='repeat(1, minmax(0, 1fr))'
                    >
                      <div>
                        <OneLineInput
                          inputLabel='Company Name'
                          type='text'
                          label='Company Name'
                          name='companyName'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={!!touched.companyName && !!errors.companyName}
                          helperText={touched.companyName && errors.companyName}
                        />
                      </div>
                      <Box height={30} />
                      <div className={styles.supplierassessmentdirections}>
                        In the fields below, list the locations of your
                        company’s largest 5 offices or facilities and the
                        purchased electricity in kilowatt hours (kWh) each uses
                        annually. If you have fewer than 5 locations, leave the
                        remaining boxes blank. This information can usually be
                        found on the utility bills you receive. Do not include
                        renewable energy.
                      </div>
                      <Box height={8} />
                      {/* Section that gets electricity usage by facility*/}
                      <LocationInput
                        label='Location (city, state)'
                        type='text'
                        name='location1'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.location1 && !!errors.location1}
                        helperText={touched.location1 && errors.location1}
                        label2='Electricity purchased (kWh)'
                        type2='number'
                        name2='location1kwh'
                        onBlur2={handleBlur}
                        onChange2={handleChange}
                        error2={!!touched.location1kwh && !!errors.location1kwh}
                        helperText2={
                          touched.location1kwh && errors.location1kwh
                        }
                      />
                      <LocationInput
                        label='Location (city, state)'
                        type='text'
                        name='location2'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.location2 && !!errors.location2}
                        helperText={touched.location2 && errors.location2}
                        label2='Electricity purchased (kWh)'
                        type2='number'
                        name2='location2kwh'
                        onBlur2={handleBlur}
                        onChange2={handleChange}
                        error2={!!touched.location2kwh && !!errors.location2kwh}
                        helperText2={
                          touched.location2kwh && errors.location2kwh
                        }
                      />
                      <LocationInput
                        label='Location (city, state)'
                        type='text'
                        name='location3'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.location3 && !!errors.location3}
                        helperText={touched.location3 && errors.location3}
                        label2='Electricity purchased (kWh)'
                        type2='number'
                        name2='location3kwh'
                        onBlur2={handleBlur}
                        onChange2={handleChange}
                        error2={!!touched.location3kwh && !!errors.location3kwh}
                        helperText2={
                          touched.location3kwh && errors.location3kwh
                        }
                      />
                      <LocationInput
                        label='Location (city, state)'
                        type='text'
                        name='location4'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.location4 && !!errors.location4}
                        helperText={touched.location4 && errors.location4}
                        label2='Electricity purchased (kWh)'
                        type2='number'
                        name2='location4kwh'
                        onBlur2={handleBlur}
                        onChange2={handleChange}
                        error2={!!touched.location4kwh && !!errors.location4kwh}
                        helperText2={
                          touched.location4kwh && errors.location4kwh
                        }
                      />
                      <LocationInput
                        label='Location (city, state)'
                        type='text'
                        name='location5'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.location5 && !!errors.location5}
                        helperText={touched.location5 && errors.location5}
                        label2='Electricity purchased (kWh)'
                        type2='number'
                        name2='location5kwh'
                        onBlur2={handleBlur}
                        onChange2={handleChange}
                        error2={!!touched.location5kwh && !!errors.location5kwh}
                        helperText2={
                          touched.location5kwh && errors.location5kwh
                        }
                      />
                      <Box height={30} /> {/* Spacer */}
                      {/* Any other electricity usage */}
                      <Width240Input
                        inputLabel='How much purchased electricity does your company use annually that is not included above? If the answer is none, enter 0 below.'
                        type='number'
                        label='Electricity purchased (kWh)'
                        name='otherelectricity'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          !!touched.otherelectricity &&
                          !!errors.otherelectricity
                        }
                        helperText={
                          touched.otherelectricity && errors.otherelectricity
                        }
                      />
                      <Box height={30} /> {/* Spacer */}
                      <SelectInput
                        inputLabel='Which year does this represent?'
                        type='number'
                        name='representationalYear'
                        value={values.representationalYear}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          !!touched.representationalYear &&
                          !!errors.representationalYear
                        }
                        helperText={
                          touched.representationalYear &&
                          errors.representationalYear
                        }
                        // menuItemValue='2022'
                        menuItemValue2='2023'
                        // menuItemValue3='2024'
                      />
                      <Box height={25} /> {/* Spacer */}
                      <SelectInput
                        inputLabel='Reporting year'
                        name='calendarOrFiscal'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.calendarOrFiscal}
                        error={
                          !!touched.calendarOrFiscal &&
                          !!errors.calendarOrFiscal
                        }
                        helperText={
                          touched.calendarOrFiscal && errors.calendarOrFiscal
                        }
                        menuItemValue='CY'
                        // menuItemValue2='FY'
                      />
                      <Box height={40} /> {/* Spacer */}
                      <div className={styles.supplierassessmentdirections}>
                        We need information about your purchases of renewable
                        energy and/or renewable energy credits. This is used to
                        determine if your location-based Scope 2 emissions are
                        different than your market-based Scope 2 emissions. List
                        the kWh of renewable energy you purchase (if any) below
                        and the locations the energy is used.
                      </div>
                      <Box height={8} />
                      {/* Section that gets renewable energy usage by facility*/}
                      <LocationInput
                        label='Location (city, state)'
                        type='text'
                        name='renewablelocation1'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          !!touched.renewablelocation1 &&
                          !!errors.renewablelocation1
                        }
                        helperText={
                          touched.renewablelocation1 &&
                          errors.renewablelocation1
                        }
                        label2='Renewable Electricity (kwH)'
                        type2='number'
                        name2='renewablelocation1kwh'
                        onBlur2={handleBlur}
                        onChange2={handleChange}
                        error2={
                          !!touched.renewablelocation1kwh &&
                          !!errors.renewablelocation1kwh
                        }
                        helperText2={
                          touched.renewablelocation1kwh &&
                          errors.renewablelocation1kwh
                        }
                      />
                      <LocationInput
                        label='Location (city, state)'
                        type='text'
                        name='renewablelocation2'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          !!touched.renewablelocation2 &&
                          !!errors.renewablelocation2
                        }
                        helperText={
                          touched.renewablelocation2 &&
                          errors.renewablelocation2
                        }
                        label2='Renewable Electricity (kwH)'
                        type2='number'
                        name2='renewablelocation2kwh'
                        onBlur2={handleBlur}
                        onChange2={handleChange}
                        error2={
                          !!touched.renewablelocation2kwh &&
                          !!errors.renewablelocation2kwh
                        }
                        helperText2={
                          touched.renewablelocation2kwh &&
                          errors.renewablelocation2kwh
                        }
                      />
                      <LocationInput
                        label='Location (city, state)'
                        type='text'
                        name='renewablelocation3'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          !!touched.renewablelocation3 &&
                          !!errors.renewablelocation3
                        }
                        helperText={
                          touched.renewablelocation3 &&
                          errors.renewablelocation3
                        }
                        label2='Renewable Electricity (kwH)'
                        type2='number'
                        name2='renewablelocation3kwh'
                        onBlur2={handleBlur}
                        onChange2={handleChange}
                        error2={
                          !!touched.renewablelocation3kwh &&
                          !!errors.renewablelocation3kwh
                        }
                        helperText2={
                          touched.renewablelocation3kwh &&
                          errors.renewablelocation3kwh
                        }
                      />
                      <LocationInput
                        label='Location (city, state)'
                        type='text'
                        name='renewablelocation4'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          !!touched.renewablelocation4 &&
                          !!errors.renewablelocation4
                        }
                        helperText={
                          touched.renewablelocation4 &&
                          errors.renewablelocation4
                        }
                        label2='Renewable Electricity (kwH)'
                        type2='number'
                        name2='renewablelocation4kwh'
                        onBlur2={handleBlur}
                        onChange2={handleChange}
                        error2={
                          !!touched.renewablelocation4kwh &&
                          !!errors.renewablelocation4kwh
                        }
                        helperText2={
                          touched.renewablelocation4kwh &&
                          errors.renewablelocation4kwh
                        }
                      />
                      <LocationInput
                        label='Location (city, state)'
                        type='text'
                        name='renewablelocation5'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          !!touched.renewablelocation5 &&
                          !!errors.renewablelocation5
                        }
                        helperText={
                          touched.renewablelocation5 &&
                          errors.renewablelocation5
                        }
                        label2='Renewable Electricity (kwH)'
                        type2='number'
                        name2='renewablelocation5kwh'
                        onBlur2={handleBlur}
                        onChange2={handleChange}
                        error2={
                          !!touched.renewablelocation5kwh &&
                          !!errors.renewablelocation5kwh
                        }
                        helperText2={
                          touched.renewablelocation5kwh &&
                          errors.renewablelocation5kwh
                        }
                      />
                      <Box height={25} /> {/* Spacer */}
                      {/* Any other renwables */}
                      <Width240Input
                        inputLabel='How much renewable energy does your company use annually that is not included above? If the answer is none, enter 0 below.'
                        type='number'
                        label='Renewable energy purchased (kWh)'
                        name='otherrenewable'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          !!touched.otherrenewable && !!errors.otherrenewable
                        }
                        helperText={
                          touched.otherrenewable && errors.otherrenewable
                        }
                      />
                      <Box height={25} /> {/* Spacer */}
                      <SelectInput
                        inputLabel='Does your company purchase steam, heat, or cooling (not common)? If so, our support team will contact you to learn more about this.'
                        name='otherscope2energypurchases'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.otherscope2energypurchases}
                        error={
                          !!touched.otherscope2energypurchases &&
                          !!errors.otherscope2energypurchases
                        }
                        helperText={
                          touched.otherscope2energypurchases &&
                          errors.otherscope2energypurchases
                        }
                        menuItemValue='Yes'
                        menuItemValue2='No'
                      />
                      <Box height={25} /> {/* Spacer */}
                      <SelectInput
                        inputLabel='Is there anything else your company does that contributes to Scope 2 emissions? If so, our support team will contact you.'
                        name='otherscope2'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.otherscope2}
                        error={!!touched.otherscope2 && !!errors.otherscope2}
                        helperText={touched.otherscope2 && errors.otherscope2}
                        menuItemValue='Yes'
                        menuItemValue2='No'
                      />
                      <Box height={25} /> {/* Spacer */}
                      <Width240Input
                        inputLabel='What percentage of your company&#39;s Scope 2 emissions should be allocated to this customer? The most common method is to determine how much of your total revenue comes from the customer. For example, if you have $100,000 in revenue and $4,500 comes from the customer, then enter 4.5 here. Another way is to determine how much of your total emissions are related to the customer, based on business activities. Enter the percentage as a number without a percent sign (i.e. for 15% enter 15).' 
                        label='Number'
                        type='number'
                        name='allocationpercentage'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          !!touched.allocationpercentage &&
                          !!errors.allocationpercentage
                        }
                        helperText={
                          touched.allocationpercentage &&
                          errors.allocationpercentage
                        }
                      />
                    </Box>
                    <Box display='flex' sx={{ m: '25px 0px 50px 0px' }}>
                      <Button
                        type='submit'
                        color='secondary'
                        variant='contained'
                      >
                        Submit Form
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </div>
          </Grid>
        </Box>
      </main>
    </>
  );
};

export default SupplierAssessment10006;
