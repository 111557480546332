const supplierEmissionsDemoAccounts = () => {
  const demoEmissions = [
    {
      company: 'Demo Company 1',
      'Scope 1' : 200.3,
      'Scope 2': 305.2
    },
    {
      company: 'Demo Company 2',
      'Scope 1' : 143.4,
      'Scope 2': 800.8
    },
    {
      company: 'Demo Company 3',
      'Scope 1' : 294.6,
      'Scope 2': 524.6
    }
  ]
  return (demoEmissions);
}

export default supplierEmissionsDemoAccounts;