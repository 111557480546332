import emailjs from '@emailjs/browser';
import getAssessmentTitle from './getAssessmentTitle';

// Sends an email to support using emailJS requesting that an
// assessment email be sent to the supplier in question.
const sendAssessmentEmail = async (
  toEmail,
  supplierName,
  supplierid,
  userEmail,
  assessmentNumber,
  assessmentID,
) => {

  const templateObj = {
    to_email: toEmail,
    sending_user: userEmail,
    supplier_name: supplierName,
    supplier_id: supplierid,
    assessment_number: assessmentNumber,
    assessment_id: assessmentID,
    assessment_name: getAssessmentTitle(assessmentNumber)
  };
  
  if (userEmail.substr(0, 4) !== 'demo') {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE,
        templateObj,
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {
          console.log(error);
          throw error;
        }
      );
  } else {
    console.log('Email not sent in demo mode.');
  }
};

export default sendAssessmentEmail;
